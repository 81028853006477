import { Box, Flex, Heading } from "@chakra-ui/react";

import { BulkPaymentsForm } from "./BulkPaymentsForm";
import { PaymentDetails } from "./PaymentDetails";

export const BulkPaymentsTab = () => {
  return (
    <Box mx={16} my={8}>
      <Heading
        as="h1"
        size="2xl"
        noOfLines={1}
        fontSize={36}
        mb={6}
        fontWeight={500}
      >
        Bulk payments
      </Heading>

      <BulkPaymentsForm />
    </Box>
  );
};
