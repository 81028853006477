import { Box, Icon, Text } from "@chakra-ui/react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { AiOutlineFontColors } from "react-icons/ai";
import {
  MdFormatPaint,
  MdOutlineCategory,
  MdOutlineSettingsSuggest,
} from "react-icons/md";
import { NavLink, useParams } from "react-router-dom";

import { ColorTab } from "./Color/ColorTab";
import { FontTab } from "./Font/FontTab";
import { LogoTab } from "./Logo/LogoTab";
import { SetupTab } from "./Setup/SetupTab";

const TabText = ({ children }: any) => {
  return (
    <Text
      fontWeight={700}
      my={2}
      fontSize={14}
      display="flex"
      alignItems="center"
    >
      {children}
    </Text>
  );
};

const TabsMap = {
  setup: 0,
  color: 1,
  font: 2,
  logo: 3,
};

export const WhiteLabelPage = () => {
  const { tab = "setup" } = useParams();
  console.log({ tab: TabsMap[tab] });

  return (
    <Box>
      <Tabs index={TabsMap[tab]}>
        <TabList pl={16} color="grayBlue.70">
          <Tab as={NavLink} to="/white-label/setup">
            <TabText>
              <Box as={MdOutlineSettingsSuggest} mr={2} fontSize={20} />
              Setup
            </TabText>
          </Tab>
          <Tab as={NavLink} to="/white-label/color">
            <TabText>
              <Icon as={MdFormatPaint} mr={2} fontSize={20} />
              Interface settings
            </TabText>
          </Tab>
          <Tab as={NavLink} to="/white-label/font">
            <TabText>
              <Box as={AiOutlineFontColors} mr={2} fontSize={20} />
              Font settings
            </TabText>
          </Tab>
          <Tab as={NavLink} to="/white-label/logo">
            <TabText>
              <Box as={MdOutlineCategory} mr={2} fontSize={20} />
              Product settings
            </TabText>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel id="setup">
            <SetupTab />
          </TabPanel>
          <TabPanel id="color">
            <ColorTab />
          </TabPanel>
          <TabPanel id="font">
            <FontTab />
          </TabPanel>
          <TabPanel id="logo">
            <LogoTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
