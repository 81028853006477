import { Icon } from "@chakra-ui/react";

export const PaymentIcon = (props: any) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      opacity="0.3"
      d="M17 6H3V14H17V6ZM10 13C8.34 13 7 11.66 7 10C7 8.34 8.34 7 10 7C11.66 7 13 8.34 13 10C13 11.66 11.66 13 10 13Z"
      fill="currentColor"
    />
    <path
      d="M17 4H3C1.9 4 1 4.9 1 6V14C1 15.1 1.9 16 3 16H17C18.1 16 19 15.1 19 14V6C19 4.9 18.1 4 17 4ZM3 14V6H17V14H3Z"
      fill="currentColor"
    />
    <path
      d="M10 7C8.34 7 7 8.34 7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7Z"
      fill="currentColor"
    />
    <path
      d="M23 7V18C23 19.1 22.1 20 21 20H4C4 19 4 19.1 4 18H21V7C22.1 7 22 7 23 7Z"
      fill="currentColor"
    />
  </Icon>
);
