import { Flex, SimpleGrid } from "@chakra-ui/react";

import { AddCorporateAccountCard } from "./AddCorporateAccountCard";
import { AddPersonalAccountCard } from "./AddPersonalAccountCard";
import { BecomeAgentCard } from "./BecomeAgentCard";

export const PromotionSection = () => {
  return (
    <Flex p={4} gap={4}>
      <AddPersonalAccountCard />
      <AddCorporateAccountCard />
      <BecomeAgentCard />
    </Flex>
  );
};
