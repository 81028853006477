import { Box, Flex, Heading } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { api } from "../../api/api";
import { Card } from "./Card";
import { CardInfo } from "./CardInfo";
import { Chart } from "./Chart";
import { EmptyCard } from "./EmptyCard";

// const cards = [
//   {
//     number: "6037 9975 9598 3090",
//     cvv: "678",
//   },
//   {
//     number: "4444 9975 9598 3090",
//     cvv: "678",
//   },
//   {
//     number: "1111 9975 9598 3090",
//     cvv: "678",
//   },
//   {
//     number: "2222 9975 9598 3090",
//     cvv: "678",
//   },
// ];

export type CardData = {
  id: number;
  number: string;
  name: string;
  status: string;
  type: string;
  patternType: string;
};

export const CardsPage = () => {
  const { data: cards = [], isLoading } = useQuery<CardData[]>(
    ["cards"],
    async () => {
      const { data } = await api.get("/api/cards");
      return data;
    }
  );
  console.log({ cards });

  const [selectedCard, setSelectedCard] = useState(cards[0]?.number);

  return (
    <Box>
      <Box
        px={12}
        pt={8}
        pb={2}
        borderBottomWidth="2px"
        borderBottomColor="grayBlue.97"
      >
        <Heading
          as="h1"
          size="2xl"
          noOfLines={1}
          fontSize={36}
          fontWeight={500}
        >
          Cards
        </Heading>
        <Flex
          maxW="100%"
          overflowX="auto"
          gap={6}
          paddingY={4}
          paddingRight={0}
          paddingLeft={2}
          my={4}
          sx={{
            color: "#333",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {cards.map((card) => (
            <Card
              card={card}
              selected={selectedCard === card.number}
              onClick={() => setSelectedCard(card.number)}
            />
          ))}
          <EmptyCard />
        </Flex>
      </Box>

      <Flex w="100%" px={16} py={6} gap={8} mb={8}>
        <Box width="40%" minW={300}>
          <CardInfo card={{ currency: "EUR" }} />
        </Box>

        <Box width="45%" minW={600}>
          <Chart />
        </Box>
      </Flex>
    </Box>
  );
};
