const config = {
  // apiBaseUrl: "http://localhost:3004",
  // apiBaseUrl: "http://localhost:8787",
  // apiBaseUrl: "http://localhost:58198",
  // apiBaseUrl: "https://mock-banking.gemba.workers.dev",
  apiBaseUrl: "https://api.banking.gemba.dev",

  googleFontsApiKey: "AIzaSyAUykyDa5sxImxzBrD9n_bIgKna0beyw9o",
};

export default config;
