import { Box, TabIndicator, Text } from "@chakra-ui/react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import { EuroIcon } from "../../assets/icons/Euro";
import { InsightsIcon } from "../../assets/icons/Insights";
import { ReactComponent as PaymentIcon } from "../../assets/icons/payments.svg";
import { ReactComponent as PlanetIcon } from "../../assets/icons/public.svg";
import { BulkPaymentsTab } from "./BulkPayments";
import { EuropeanPaymentsTab } from "./EuropeanPayments";
import { InternalPaymentsTab } from "./InternalPayments/InternalPaymentsTab";
import { InternationalPaymentsTab } from "./InternationalPayments";

const TabText = ({ children }: any) => {
  return (
    <Tab
      _selected={{
        color: "primary",
        borderBottom: "2px solid",
        borderColor: "primary",
      }}
    >
      {/* <Tab> */}
      <Text
        fontWeight={700}
        mb={2}
        fontSize={14}
        display="flex"
        alignItems="center"
      >
        {children}
      </Text>
    </Tab>
  );
};

export const PaymentsPage = () => {
  return (
    <Box>
      <Tabs>
        <TabList pl={16} my={4} color="grayBlue.70">
          <TabText>
            <Box as={PaymentIcon} mr={2} />
            Internal payments
          </TabText>
          <TabText>
            <Box as={PlanetIcon} mr={2} />
            International payments
          </TabText>
          <TabText>
            <EuroIcon mr={2} />
            European payments
          </TabText>

          <TabText>
            <InsightsIcon mr={2} w="24px" h="24px" />
            Bulk payments
          </TabText>
        </TabList>

        <TabPanels>
          <TabPanel>
            <InternalPaymentsTab />
          </TabPanel>
          <TabPanel>
            <InternationalPaymentsTab />
          </TabPanel>
          <TabPanel>
            <EuropeanPaymentsTab />
          </TabPanel>
          <TabPanel>
            <BulkPaymentsTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
