import { ChevronDownIcon, RepeatIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { MdInfoOutline, MdOutlinePersonOutline } from "react-icons/md";

const CenterMenuItem = ({ title }: { title: string }) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon fontSize={20} />}
        background="none"
        color="#5C7099"
        borderRadius={22}
        fontSize={14}
        pr={5}
        pl={6}
        boxShadow="none"
      >
        {title}
      </MenuButton>
      <MenuList
        borderRadius={16}
        fontSize={14}
        fontWeight={700}
        color="#7D8FB3"
        boxShadow="0px 30px 60px rgba(0, 0, 0, 0.2)"
        zIndex={10}
      >
        <MenuItem
          icon={
            <Box fontSize={24}>
              <MdOutlinePersonOutline />
            </Box>
          }
        >
          Profile
        </MenuItem>
        <MenuDivider />
        <MenuItem
          icon={
            <Box fontSize={24}>
              <MdOutlinePersonOutline />
            </Box>
          }
        >
          Referrals
        </MenuItem>
        <MenuDivider />
        <MenuItem
          icon={
            <Text fontSize={24} lineHeight="24px">
              <MdOutlinePersonOutline />
            </Text>
          }
        >
          Switch account
        </MenuItem>
        <MenuDivider />
        <MenuItem
          icon={
            <Box fontSize={24}>
              <MdOutlinePersonOutline />
            </Box>
          }
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export const CenterMenu = () => {
  return (
    <Flex gap={4} flex={1} justifyContent="center">
      {/* <CenterMenuItem title="Personal" />
      <CenterMenuItem title="Company" />
      <CenterMenuItem title="Apps" /> */}
    </Flex>
  );
};
