import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { AiTwotoneCheckCircle } from "react-icons/ai";
import { MdOutlineDns, MdOutlineRefresh } from "react-icons/md";
import { TbWorld } from "react-icons/tb";

import { WizardCard } from "../WizardCard";

export const SetupTab = () => {
  const formik = useFormik({
    initialValues: {
      subject: "",
      text: "",
    },
    // validationSchema: MessageSchema,
    onSubmit: async (values) => {
      await new Promise((r) => setTimeout(r, 3000));
      // await mutation.mutate(values);
      // toast({
      //   title: "Message sent",
      //   status: "success",
      //   duration: 4000,
      //   position: "top",
      //   isClosable: true,
      // });
      formik.resetForm();
    },
  });

  return (
    <Box mx={12} my={0}>
      <Heading as="h1" size="2xl" fontSize={36} mb={6} fontWeight={500}>
        Setup White Label domain
      </Heading>

      <Flex>
        <Box width="60%" maxW={800} mr={8}>
          <form>
            <Stack spacing={8} mb={8}>
              <InputGroup
                transition="box-shadow .3s"
                _hover={{
                  boxShadow: "0px 2px 15px rgba(51, 191, 255, 0.15)",
                }}
              >
                <InputLeftAddon
                  pointerEvents="none"
                  bgColor="#fff"
                  color="grayBlue.50"
                  fontSize={20}
                  children={
                    <>
                      <Box as={TbWorld} mr={2} />

                      <Text fontSize={14} fontWeight={700}>
                        https://
                      </Text>
                    </>
                  }
                />
                <Input
                  bgColor="#fff"
                  type="text"
                  name="url"
                  // value={values.extaccountnumber}
                  // onChange={handleChange}
                />
              </InputGroup>

              <InputGroup
                transition="box-shadow .3s"
                _hover={{
                  boxShadow: "0px 2px 15px rgba(51, 191, 255, 0.15)",
                }}
              >
                <InputLeftAddon
                  pointerEvents="none"
                  bgColor="#fff"
                  color="grayBlue.50"
                  fontSize={20}
                  children={
                    <>
                      <Box as={MdOutlineDns} mr={2} />
                      <Text fontSize={14} fontWeight={700}>
                        DNS Registrant
                      </Text>
                    </>
                  }
                />
                <Select borderLeftRadius={0} bgColor="#fff">
                  <option value="option1">Godaddy</option>
                  <option value="option2">Cloudflare</option>
                  <option value="option3">Custom</option>
                </Select>
              </InputGroup>
            </Stack>

            <Button variant="primary" size="md" w="100%">
              Setup Domain
            </Button>
          </form>

          <Box
            mt={8}
            background="grayBlue.99"
            boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
            borderRadius={8}
          >
            <Text color="grayBlue.50" fontSize={18} fontWeight={700} p={4}>
              Domain setup status
            </Text>

            <Divider />

            <Box bgColor="#fff" p={4} px={8}>
              <Flex>
                <Text color="grayBlue.50" fontSize={14} fontWeight={700} mr={2}>
                  Domain status:
                </Text>
                <Text color="green.300" fontSize={14} fontWeight={700}>
                  Ready
                  <Icon as={AiTwotoneCheckCircle} fontSize={8} ml={1} />
                </Text>
              </Flex>
              <Divider my={4} />

              <InputGroup
                transition="box-shadow .3s"
                _hover={{
                  boxShadow: "0px 2px 15px rgba(51, 191, 255, 0.15)",
                }}
              >
                <InputLeftAddon
                  pointerEvents="none"
                  bgColor="#fff"
                  color="grayBlue.50"
                  children={
                    <>
                      <Text fontSize={14} fontWeight={700}>
                        Domain name:
                      </Text>
                    </>
                  }
                />
                <Input bgColor="#fff" type="text" name="url" />
              </InputGroup>
              <Divider my={4} />

              <InputGroup
                transition="box-shadow .3s"
                _hover={{
                  boxShadow: "0px 2px 15px rgba(51, 191, 255, 0.15)",
                }}
              >
                <InputLeftAddon
                  pointerEvents="none"
                  bgColor="#fff"
                  color="grayBlue.50"
                  fontSize={14}
                  fontWeight={700}
                  children={
                    <>
                      <Text>Short name:</Text>
                    </>
                  }
                />
                <Input bgColor="#fff" type="text" name="url" />
              </InputGroup>

              <Divider my={4} />

              <FormLabel color="grayBlue.50" fontSize={14} fontWeight={700}>
                HTML Template:
              </FormLabel>
              <Textarea
                rows={8}
                border="2px solid #F0F2F7"
                boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
                borderRadius={12}
                placeholder="Add Payment details..."
                mb={4}
                defaultValue={`
<html>
  <head>
  <style type="text/css">
    h1 {color:red; font-size:36px;}
  </style>
  </head>   
</html>
                `}
              ></Textarea>

              <Flex justifyContent="space-between">
                <Button variant="secondary">
                  <Icon as={MdOutlineRefresh} fontSize={20} mr={1} />
                  Refresh
                </Button>
                <Button variant="primary">Apply</Button>
              </Flex>
            </Box>
          </Box>
        </Box>

        <Box w="40%">
          <WizardCard />
        </Box>
      </Flex>
    </Box>
  );
};
