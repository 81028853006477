import { InfoOutlineIcon, SunIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text, Tooltip } from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import { SyntheticEvent, useState } from "react";

import { FreezeIcon } from "../../assets/icons/Freeze";
import { CardData } from "./CardsPage";
import pattern1 from "./patterns/pattern_1.png";
// import pattern2 from "./patterns/pattern_2.png";
import pattern3 from "./patterns/pattern_3.png";

enum CardStatus {
  ACTIVE = "active",
  FROZEN = "frozen",
}

const CardProps = {
  type1: {
    pattern: pattern1,
    backgroundColor: "primary",
  },
  type2: {
    pattern: pattern1,
    backgroundColor: "primary",
  },
  type3: {
    pattern: pattern3,
    backgroundColor: "primary",
  },
};

type Props = {
  card: CardData;
  selected: boolean;
  onClick: any;
};

export const Card = ({ card, selected, onClick }: Props) => {
  const cardProps = CardProps[card.patternType] || CardProps.type1;
  const [status, setStatus] = useState(card.status);
  const isFrozen = status === CardStatus.FROZEN;
  const theme = useTheme();
  console.log({ theme });

  const toggleFreeze = (e: SyntheticEvent) => {
    e.stopPropagation();
    setStatus((prev) => {
      console.log({ prev });
      return prev === CardStatus.ACTIVE ? CardStatus.FROZEN : CardStatus.ACTIVE;
    });
  };

  return (
    <Box
      position="relative"
      borderRadius={16}
      // borderWidth={8}
      // borderWidth={selected ? 8 : 0}
      // borderColor={selected ? "grayBlue.80" : "transparent"}
      onClick={onClick}
      overflow="hidden"
      minW={311}
      h={200}
      cursor="pointer"
      backgroundColor={cardProps.backgroundColor}
      boxShadow={
        selected ? `0px 2px 10px ${cardProps.backgroundColor}` : undefined
      }
      transition="0.1s box-shadow linear"
    >
      <Flex
        justifyContent="flex-end"
        p={4}
        position="absolute"
        w="100%"
        zIndex={1}
      >
        {isFrozen ? (
          <Tooltip label={<Text fontSize={12}>Unfreeze</Text>}>
            <IconButton
              w="24px"
              h="24px"
              variant="primary"
              border="1px solid #fff"
              fontSize={20}
              minW="auto"
              borderRadius="50%"
              aria-label="Unfreeze"
              icon={<FreezeIcon />}
              onClick={toggleFreeze}
            />
          </Tooltip>
        ) : (
          <Tooltip label={<Text fontSize={12}>Freeze</Text>}>
            <IconButton
              w="24px"
              h="24px"
              fontSize={20}
              minW="auto"
              borderRadius="50%"
              variant="outline"
              aria-label="Freeze"
              icon={<FreezeIcon />}
              onClick={toggleFreeze}
            />
          </Tooltip>
        )}
      </Flex>
      <Flex
        h="100%"
        w="100%"
        direction="column"
        backgroundImage={`url(${cardProps.pattern})`}
        backgroundColor={cardProps.backgroundColor}
        borderRadius={0}
        justifyContent="flex-end"
        filter={isFrozen ? "blur(6px)" : undefined}
        transition="0.2s filter linear"
      >
        <Box>
          <Text fontSize="16px" fontWeight={700} color="#fff" px={4}>
            {card.number}
          </Text>
          <Text fontSize="14px" fontWeight={500} color="#fff" px={4}>
            {card.name}
          </Text>
        </Box>
        <Box display="flex" p={4}>
          <Text fontSize="0.75rem" color="#fff" mr={2}>
            ••/••
          </Text>
          <Text fontSize="0.75rem" color="#fff">
            CVC •••
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
