import { Box, Flex, Text } from "@chakra-ui/react";

import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow_down.svg";
import { ReactComponent as CsvIcon } from "../../assets/icons/csv.svg";
import { ReactComponent as PdfIcon } from "../../assets/icons/pdf.svg";
import { OutlinedButton } from "../../components/Button";

export const Header = () => {
  return (
    <Flex alignItems="center" py={8}>
      <Text fontSize="4xl" fontWeight={700}>
        Transactions
      </Text>

      <Flex flex={1} justifyContent="center" alignItems="center">
        <Text fontSize={14} fontWeight={700} color="grayBlue.70" mr={4}>
          Compared to Dec 2 - Dec 31, 2023
        </Text>
        <OutlinedButton>
          Last 30 Days <ArrowDownIcon />
        </OutlinedButton>
      </Flex>

      <Box>
        <OutlinedButton color="grayBlue.70" mr={2}>
          <Box as={PdfIcon} mr={1} /> Save as PDF
        </OutlinedButton>
        <OutlinedButton color="grayBlue.70" mr={2}>
          <Box as={CsvIcon} mr={1} /> Save as CSV
        </OutlinedButton>
        <OutlinedButton color="grayBlue.70" mr={2}>
          MT940
        </OutlinedButton>
        <OutlinedButton color="grayBlue.70" mr={2}>
          DATEV
        </OutlinedButton>
        <OutlinedButton color="grayBlue.70" mr={2}>
          QIF
        </OutlinedButton>
        <OutlinedButton color="grayBlue.70">OFX</OutlinedButton>
      </Box>
    </Flex>
  );
};
