import { Icon } from "@chakra-ui/react";

export const CopyIcon = (props: any) => (
  <Icon viewBox="0 0 12 12" {...props}>
    <path
      d="M7.65 11H2.75C1.75 11 1 10.25 1 9.25001V4.35001C1 3.40001 1.75 2.60001 2.75 2.60001H7.65C8.6 2.60001 9.4 3.35001 9.4 4.35001V9.25001C9.35 10.25 8.6 11 7.65 11ZM2.75 3.65001C2.35 3.65001 2 3.95001 2 4.35001V9.25001C2 9.65001 2.35 10 2.75 10H7.65C8.05 10 8.4 9.65001 8.4 9.25001V4.35001C8.4 3.95001 8.05 3.60001 7.65 3.60001H2.75V3.65001Z"
      fill="currentColor"
    />
    <path
      d="M10.5 8.95C10.2 8.95 9.99999 8.75 9.99999 8.45V3.15C9.99999 2.5 9.49999 2 8.84999 2H3.54999C3.24999 2 3.04999 1.8 3.04999 1.5C3.04999 1.2 3.24999 1 3.54999 1H8.84999C10.05 1 11 1.95 11 3.15V8.45C11 8.75 10.8 8.95 10.5 8.95Z"
      fill="currentColor"
    />
  </Icon>
);
