import {
  Box,
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";

import { api } from "../../api/api";
import { DashboardCard } from "../../components/Card";

type Message = {
  id: number;
  author: string;
  subject: string;
  text: string;
  date: number;
};

const StyledTh = ({ children }: any) => {
  return (
    <Th textTransform="none" color="grayBlue.80">
      {children}
    </Th>
  );
};

const MessageTd = ({ message }: { message: Message }) => {
  return (
    <Tr>
      <Td color="grayBlue.50">{message.author}</Td>
      <Td
        color="grayBlue.70"
        fontSize={12}
        maxW={140}
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {message.subject}
      </Td>
      <Td color="grayBlue.70" fontSize={12}>
        {format(message.date, "dd.MM.yyyy")}
      </Td>
    </Tr>
  );
};

export const MessagesCard = () => {
  const { data: messages = [], isLoading } = useQuery<Message[]>(
    ["messages"],
    async () => {
      const { data } = await api.get(
        `/api/messages?_page=1&_limit=3&_sort=date&_order=desc`
      );
      return data;
    },
    {
      retry: false,
    }
  );

  return (
    <DashboardCard height="fit-content">
      <Box mb={2} px={2}>
        <Text fontWeight={800} fontSize={14} color="grayBlue.70">
          Internal Messages
        </Text>
      </Box>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <StyledTh>Author</StyledTh>
              <StyledTh>Subject</StyledTh>
              <StyledTh>Date</StyledTh>
            </Tr>
          </Thead>
          {messages && (
            <Tbody>
              {messages.map((m) => {
                return <MessageTd key={m.id} message={m} />;
              })}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <Flex justifyContent="space-between" mt={4}>
        <Button as={NavLink} to="/messages" variant="outline" size="sm">
          All Messages
        </Button>
        <Button as={NavLink} to="/messages/new" variant="outline" size="sm">
          New Message
        </Button>
      </Flex>
    </DashboardCard>
  );
};
