import { Box, Heading } from "@chakra-ui/react";

import { InternalPaymentsForm } from "./InternalPaymentsForm";

export const InternalPaymentsTab = () => {
  return (
    <Box mx={16} my={8}>
      <Heading as="h1" size="2xl" fontSize={36} mb={6} fontWeight={500}>
        Internal payments
      </Heading>

      <InternalPaymentsForm />
    </Box>
  );
};
