import { Badge, Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { StatementRecord } from "api/accountApi";
import { InfoIcon } from "assets/icons/Info";
import { format, parse } from "date-fns";
import { MdOutlinePersonOutline } from "react-icons/md";
import { formatBalance } from "utils/formatters";

const EXCHANGE_RATE = 1.0121;
const FEE = 1.5;

const InfoRow = ({ label, value }: any) => {
  return (
    <Flex justifyContent="space-between" mb={4}>
      <Text color="grayBlue.70" fontSize={14} fontWeight={500}>
        {label}
      </Text>
      <Text color="grayBlue.50" fontSize={14} fontWeight={500}>
        {value}
      </Text>
    </Flex>
  );
};

export const TransactionInfo = ({ record }: { record: StatementRecord }) => {
  return (
    <Box>
      <Flex justifyContent="space-between" mb={4}>
        <Flex alignItems="center">
          <Text fontSize={20} mr={2} color="grayBlue.70">
            <MdOutlinePersonOutline />
          </Text>
          <Text fontSize={14} fontWeight={500} color="grayBlue.70" mr={2}>
            By Alexander Legoshin
          </Text>
          <Badge bgColor="primary">By you</Badge>
        </Flex>
      </Flex>

      <Flex alignItems="center" mb={4}>
        <Text fontSize={16} fontWeight={600} color="grayBlue.70" mr={2}>
          Transaction details
        </Text>
        <Tooltip
          label="Here you can see all transaction details"
          hasArrow
          placement="right"
        >
          <Box>
            <InfoIcon cursor="pointer" fontSize={20} color="grayBlue.70" />
          </Box>
        </Tooltip>
      </Flex>

      <InfoRow label="Moved to" value="Your USD account" />
      <InfoRow
        label="Completed on"
        value={
          format(parse(record.date, "yyyy-MM-d", new Date()), "d MMMM") +
          " @ 12:02 PM"
        }
        // value="22 September @ 12:02 PM  @ 12:02 CET"
      />
      <InfoRow
        label="Amount converted"
        value={formatBalance(record.amount, record.currency)}
      />
      <InfoRow
        label="Converted to"
        value={formatBalance(record.amount * EXCHANGE_RATE, "USD")}
      />
      <InfoRow label="Exchange rate" value={EXCHANGE_RATE} />
      <InfoRow label="Fee" value={formatBalance(FEE, record.currency)} />
      <InfoRow label="Transaction ID" value={record.transaction_reference} />
    </Box>
  );
};
