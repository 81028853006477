import { Box, Text } from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useField } from "formik";

import { Statement, accountApi } from "../../../api/accountApi";
import { formatAccountName, formatBalance } from "../../../utils/formatters";
import { Account } from "../../Dashboard/Accounts";

type Props = {
  accounts: Account[];
  selectedAccountId?: string;
  handleChange: any;
  accountFieldName: string;
};

export const ToAccountSelect = ({
  accounts,
  handleChange,
  accountFieldName,
}: Props) => {
  const [{ value: selectedAccountId }] = useField(accountFieldName);

  const { data: statement, isLoading: isStatementLoading } =
    useQuery<Statement>(
      ["statement", selectedAccountId],
      async () => {
        return accountApi.getStatement(selectedAccountId);
      },
      {
        enabled: !!selectedAccountId,
      }
    );

  return (
    <Box p={8} border="1px solid #CFD6E6;" borderRadius={6}>
      <Select
        placeholder="Payment account"
        name={accountFieldName}
        size="md"
        mb={4}
        bgColor="#fff"
        border="none"
        value={selectedAccountId}
        onChange={handleChange}
      >
        {accounts.map((acc) => {
          return (
            <option key={acc.id} value={acc.id}>
              {formatAccountName(acc)}
            </option>
          );
        })}
      </Select>

      <Text fontWeight={500} fontSize={18} color="#7D8FB3" ml={2}>
        {statement
          ? formatBalance(
              statement.balance.closing.balance,
              statement.balance.currency
            )
          : "-"}
      </Text>
      <Text fontWeight={500} fontSize={10} color="#B8C3D9" ml={2}>
        Available in your account
      </Text>
    </Box>
  );
};
