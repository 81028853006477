import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import { CloudUploadIcon } from "../../../assets/icons/CloudUpload";
import { SecondaryButton } from "../../../components/Button";
import { FileUpload } from "../common/FileUpload";

export const PaymentDetails = ({
  name = "paymentsfile",
}: {
  name?: string;
}) => {
  return (
    <Box
      borderRadius={16}
      boxShadow="0px 60px 120px rgba(38, 51, 77, 0.1);"
      display="flex"
      flexDirection="column"
    >
      <Box bgColor="#FAFBFC" borderRadius="16px 16px 0px 0px" p={4}>
        <Text fontSize={18} fontWeight={700}>
          Bulk Payments file *
        </Text>
      </Box>
      <Box bgColor="#fff" p={4} borderRadius=" 0px 0px 16px 16px">
        <FileUpload
          buttonLabel="Upload Bulk payment file"
          options={{
            multiple: false,
            accept: {
              "text/csv": [
                ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
              ],
            },
          }}
        />
      </Box>
    </Box>
  );
};
