import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useState } from "react";
import { HexColorPicker } from "react-colorful";

export const ColorPicker = ({ initialColor }: any) => {
  const [color, setColor] = useState(initialColor);

  return (
    <Popover>
      <PopoverTrigger>
        {/* <Button>Trigger</Button> */}
        <Box
          w="55px"
          h="40px"
          p={2}
          borderRadius={3}
          border="2px"
          borderColor="grayBlue.95"
          cursor="pointer"
        >
          <Box
            borderRadius={3}
            w="100%"
            h="100%"
            bgColor={color}
            boxShadow="0px 1.9063px 4.76574px rgba(0, 0, 0, 0.15);"
          ></Box>
        </Box>
      </PopoverTrigger>
      <PopoverContent w="240px" h="150px">
        <PopoverArrow />
        <PopoverBody
          w="100%"
          h="100%"
          p={2}
          sx={{
            "& .react-colorful": {
              width: "100%",
              height: "100%",
            },
            "& .react-colorful__saturation": {
              borderRadius: "4px",
            },
            "& .react-colorful__hue-pointer": {
              width: "13px",
              height: "13px",
            },
            "& .react-colorful__saturation-pointer": {
              width: "13px",
              height: "13px",
            },
            "& .react-colorful__last-control": {
              height: "4px",
              borderRadius: "2px",
              marginTop: "6px",
              marginBottom: "6px",
            },
          }}
        >
          <HexColorPicker color={color} onChange={setColor} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
