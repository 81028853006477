import { extendTheme } from "@chakra-ui/react";

import { BadgeTheme } from "../../components/Badge";
import { Button } from "../../components/Button/buttonTheme";
import { IconButton } from "../../components/IconButton/themeOverride";

const theme = extendTheme({
  components: {
    Button,
    Badge: BadgeTheme,
    IconButton,
    InputGroup: {
      _hover: {
        boxShadow: "0px 2px 15px rgba(51, 191, 255, 0.15)",
      },
    },
  },
  styles: {
    global: {
      a: {
        color: "primary",
      },
    },
  },
  fonts: {
    heading: `'PT Root', sans-serif`,
    body: `'PT Root', sans-serif`,
  },
  // fonts: {
  //   body: {
  //     fontFamily: `'PT Root'`,
  //   },
  //   heading: {
  //     fontFamily: `'PT Root'`,
  //   },
  // },
  colors: {
    black: "#1C1C1C",
    primary: "#2066E6",
    blue: {
      100: "#406bff",
      200: "#4d74ff",
      300: "#597eff",
      400: "#6688ff",
      500: "#2066E6",
      600: "#2657ff",
      700: "#0d43ff",
      800: "#0039ff",
      900: "#0033e5",
    },
    grayBlue: {
      50: "#405580",
      55: "#4D628C",
      60: "#5C7099",
      70: "#7D8FB3",
      80: "#A3B1CC",
      85: "#B8C3D9",
      90: "#CFD6E5",
      95: "#E6EAF2",
      97: "#f0f2f7",
      99: "#fafbfc",
    },
    grey: {
      70: "#7D8FB3",
      85: "#B8C3D9",
    },
    green: {
      500: "#29CC39",
    },
    darkGreen: {
      5: "#1DBF2D",
    },
  },
});

export default theme;
