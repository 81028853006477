import { Icon } from "@chakra-ui/react";

export const InfoIcon = (props: any) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <rect width="32" height="32" rx="16" fill="#F5F7FA" />
    <path
      d="M14.6665 9.33335H17.3332V12H14.6665V9.33335ZM14.6665 14.6667H17.3332V22.6667H14.6665V14.6667ZM15.9998 2.66669C8.63984 2.66669 2.6665 8.64002 2.6665 16C2.6665 23.36 8.63984 29.3334 15.9998 29.3334C23.3598 29.3334 29.3332 23.36 29.3332 16C29.3332 8.64002 23.3598 2.66669 15.9998 2.66669ZM15.9998 26.6667C10.1198 26.6667 5.33317 21.88 5.33317 16C5.33317 10.12 10.1198 5.33335 15.9998 5.33335C21.8798 5.33335 26.6665 10.12 26.6665 16C26.6665 21.88 21.8798 26.6667 15.9998 26.6667Z"
      fill="currentColor"
    />
  </Icon>
);
