import {
  Badge,
  Box,
  Button,
  Link as ChakraLink,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { PaymentDetails } from "../common/PaymentDetails";
import { InternalPaymentsForm } from "./InternalPaymentsForm";

export const SuccessCard = ({ amount, accountId, onCreateNew }) => {
  return (
    <Flex
      h="100%"
      justifyContent="center"
      alignItems="center"
      direction="column"
      bgColor="#fff"
      p={4}
      borderRadius={16}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        flex={1}
      >
        <Flex alignItems="center" mb={8}>
          <Badge bgColor="green.500" mr={2}>
            Complete
          </Badge>
          <Text fontSize={22} fontWeight={500}>
            {amount}
          </Text>
        </Flex>

        <Text color="grayBlue.85" fontWeight={500} mb={8}>
          Transaction Complete
        </Text>

        <Button
          as={Link}
          to={`/account/${accountId}/transactions`}
          // color="primary"
          variant="outline"
          fontSize={13}
          fontWeight={600}
        >
          All accounts transactions
        </Button>
        {/* <Button variant="outline">All accounts transactions</Button> */}
      </Flex>

      <Flex justifyContent="flex-end" mt={4}>
        <Button mr={8} size="md" variant="noBorder">
          Save as Template
        </Button>

        <Button variant="primary" type="submit" px={32} onClick={onCreateNew}>
          Create New Payment
        </Button>
      </Flex>
    </Flex>
  );
};
