import { ChakraProvider, Grid, GridItem } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthContext, AuthProvider } from "auth/AuthProvider";
import { LoginPage, SignupPage } from "pages/Auth";
import { OnboardingPage } from "pages/Onboarding";
import { SetThemePage } from "pages/SetTheme/SetThemePage";
import { SettingsPage } from "pages/Settings";
import { SingleTransactionPage } from "pages/Transactions/Single/SingleTransactionPage";
import { ThemeProvider } from "providers/theme/ThemeProvider";
import { useContext } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import "./App.less";
import { Header } from "./layout/Header";
import { Sidebar } from "./layout/Sidebar";
import { AccountPage } from "./pages/Account";
import { CardsPage } from "./pages/Cards";
import { Dashboard } from "./pages/Dashboard";
import { MessagesPage } from "./pages/Messages/MessagesPage";
import { NewMessagePage } from "./pages/Messages/NewMessagePage";
import { ViewMessagePage } from "./pages/Messages/ViewMessagePage";
import { PaymentsPage } from "./pages/Payments/PaymentsPage";
import { TransactionsPage } from "./pages/Transactions";
import { WhiteLabelPage } from "./pages/WhiteLabel";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function Layout() {
  return (
    <Grid
      templateAreas={`"header header"
                  "nav main"`}
      gridTemplateRows={"64px 1fr"}
      gridTemplateColumns={"250px 1fr"}
      bg="#fafbfc"
      w="100%"
      h="100%"
      maxW="100%"
    >
      <GridItem area={"header"}>
        <Header />
      </GridItem>
      <GridItem area={"nav"}>
        <Sidebar />
      </GridItem>
      <GridItem area={"main"} minW={800}>
        <Outlet />
      </GridItem>
    </Grid>
  );
}

const allowedRoutes = ["/login", "/sign-up", "/load-theme"];

function Main() {
  const { token, userValidated } = useContext(AuthContext);
  const location = useLocation();

  console.log(location.pathname);

  if (location.pathname === "/load-theme") {
    return <SetThemePage />;
  }

  if (!token) {
    if (!allowedRoutes.includes(location.pathname)) {
      return <Navigate to="/login" replace />;
    }

    return (
      <Routes>
        <Route index element={<LoginPage />} />
        <Route path="/sign-up" element={<SignupPage />} />
        <Route path="/login" index element={<LoginPage />} />
        <Route path="/load-theme" element={<SetThemePage />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {!userValidated && (
          <>
            <Route index element={<OnboardingPage />} />
            <Route path="/onboarding" element={<OnboardingPage />} />
            {/* <Route path="/personal" element={<OnboardingPage />} />
                <Route path="/phone-verify" element={<PhoneVerifyPage />} /> */}
            {/* <Route path="/confirmation" element={<ConfirmationPage />} /> */}
            <Route path="/messages" element={<MessagesPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/messages/new" element={<NewMessagePage />} />
            <Route path="/messages/:messageId" element={<ViewMessagePage />} />
            <Route path="/load-theme" element={<SetThemePage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}

        {userValidated && (
          <>
            <Route index element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/transactions" element={<TransactionsPage />} />
            <Route path="/accounts/:accountId" element={<AccountPage />} />
            <Route
              path="/transactions/:transactionId"
              element={<SingleTransactionPage />}
            />
            <Route path="/cards" element={<CardsPage />} />
            <Route path="/payments" element={<PaymentsPage />} />
            <Route path="/messages" element={<MessagesPage />} />
            <Route path="/messages/new" element={<NewMessagePage />} />
            <Route path="/messages/:messageId" element={<ViewMessagePage />} />
            <Route path="/white-label/:tab?" element={<WhiteLabelPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/load-theme" element={<SetThemePage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </Route>
    </Routes>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <Main />
        </ThemeProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}
