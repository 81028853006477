import { Icon } from "@chakra-ui/react";

export const MoreIcon = (props: any) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M5.00016 8.33331C4.0835 8.33331 3.3335 9.08331 3.3335 9.99998C3.3335 10.9166 4.0835 11.6666 5.00016 11.6666C5.91683 11.6666 6.66683 10.9166 6.66683 9.99998C6.66683 9.08331 5.91683 8.33331 5.00016 8.33331ZM15.0002 8.33331C14.0835 8.33331 13.3335 9.08331 13.3335 9.99998C13.3335 10.9166 14.0835 11.6666 15.0002 11.6666C15.9168 11.6666 16.6668 10.9166 16.6668 9.99998C16.6668 9.08331 15.9168 8.33331 15.0002 8.33331ZM10.0002 8.33331C9.0835 8.33331 8.3335 9.08331 8.3335 9.99998C8.3335 10.9166 9.0835 11.6666 10.0002 11.6666C10.9168 11.6666 11.6668 10.9166 11.6668 9.99998C11.6668 9.08331 10.9168 8.33331 10.0002 8.33331Z"
      fill="currentColor"
    />
  </Icon>
);
