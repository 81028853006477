import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { countries } from "country-flag-icons";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { getName } from "country-list";
import { useMemo, useState } from "react";
import {
  MdAlternateEmail,
  MdLockOutline,
  MdOutlineSmartphone,
} from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { VscKey } from "react-icons/vsc";

import { UserIcon } from "../../assets/icons/User";

const initialData = {
  debitaccountid: "",
  amount: "0",
  extaccountnumber: "",
  benefname: "",
  registrationnumber: "",
  benefcountry: "",
  benefaddress: "",
  extbankswift: "",
  extbankname: "",
  extbenefbankcountry: "",
  extbankaddress: "",
  benefemail: "",
  paymentsfile: undefined,
};

export type InternationalPaymentsFormData = typeof initialData;

export const PersonalAccountForm = ({ onSubmit }: any) => {
  const [showPassword, setShowPassword] = useState(false);
  // const navigate = useNavigate();
  // const handleSubmit = () => {
  //   console.log("sub");
  //   navigate("/phone-verify");
  // };

  console.log({ countries });

  const countryData = useMemo(() => {
    return countries
      .map((country) => ({
        code: country,
        name: getName(country),
      }))
      .filter((country) => country.name);
  }, []);

  console.log({ flag: getUnicodeFlagIcon("US") });
  console.log({ countryData });

  return (
    <>
      <Text fontSize="4xl" fontWeight={700} mt={4}>
        Personal Account Application
      </Text>

      <Divider my={4} />

      <form>
        <Flex>
          <Box width="60%" mr={8}>
            <Stack spacing={8}>
              <InputGroup>
                <InputLeftAddon
                  pointerEvents="none"
                  bgColor="#fff"
                  color="grayBlue.50"
                  children={
                    <>
                      <UserIcon width="20px" height="20px" />
                      <Text ml={2}>First name* :</Text>
                    </>
                  }
                />
                <Input
                  bgColor="#fff"
                  type="text"
                  // placeholder="First name"
                  name="benefname"
                />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon
                  pointerEvents="none"
                  bgColor="#fff"
                  color="grayBlue.50"
                  children={
                    <>
                      <UserIcon width="20px" height="20px" />
                      <Text ml={2}>Last name* :</Text>
                    </>
                  }
                />
                <Input
                  bgColor="#fff"
                  type="text"
                  // placeholder="First name"
                  name="benefname"
                />
              </InputGroup>

              <InputGroup>
                <InputLeftAddon
                  pointerEvents="none"
                  bgColor="#fff"
                  color="grayBlue.50"
                  children={
                    <>
                      <TbWorld width={20} height={20} />
                      <Text ml={2}>Country* :</Text>
                    </>
                  }
                />
                <Select
                  name="country"
                  bgColor="#fff"
                  borderTopLeftRadius={0}
                  borderBottomLeftRadius={0}
                >
                  {countryData.map((country) => (
                    <option value={country.code}>
                      {country.code && getUnicodeFlagIcon(country.code)}
                      &nbsp;
                      {country.name}
                    </option>
                  ))}
                </Select>
              </InputGroup>

              <InputGroup>
                <InputLeftAddon
                  pointerEvents="none"
                  bgColor="#fff"
                  color="grayBlue.50"
                  children={
                    <>
                      <MdAlternateEmail width={20} height={20} />
                      <Text ml={2}>Email* :</Text>
                    </>
                  }
                />
                <Input type="text" bgColor="#fff" name="email" />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon
                  pointerEvents="none"
                  bgColor="#fff"
                  color="grayBlue.50"
                  children={
                    <>
                      <MdOutlineSmartphone width={20} height={20} />
                      <Text ml={2}>Phone* :</Text>
                    </>
                  }
                />
                <Input type="text" bgColor="#fff" name="email" />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon
                  pointerEvents="none"
                  bgColor="#fff"
                  color="grayBlue.50"
                  children={
                    <>
                      <VscKey width={20} height={20} />
                      <Text ml={2}>Referral code :</Text>
                    </>
                  }
                />
                <Input type="text" bgColor="#fff" name="referralcode" />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon
                  pointerEvents="none"
                  bgColor="#fff"
                  color="grayBlue.50"
                  children={
                    <>
                      <MdLockOutline width={20} height={20} />
                      <Text ml={2}>Password :</Text>
                    </>
                  }
                />
                <Input
                  type={showPassword ? "text" : "password"}
                  bgColor="#fff"
                  name="password"
                />
                <InputRightElement h={"full"}>
                  <IconButton
                    aria-label="show password"
                    variant={"ghost"}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </IconButton>
                </InputRightElement>
              </InputGroup>
            </Stack>

            <Divider my={8} />

            <Flex mt={4}>
              <Button
                variant="primary"
                // type="submit"
                // disabled={isSubmitting}
                px={32}
                w="100%"
                onClick={onSubmit}
              >
                Create Account
              </Button>
            </Flex>
          </Box>
          <Box width="40%">
            <Box
              py={4}
              px={8}
              bgColor="white"
              boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
              borderRadius={8}
              maxW={500}
            >
              <Text color="grayBlue.50" fontSize={18} fontWeight={700} mb={4}>
                Identification documents
              </Text>
              <Flex mb={4}>
                <Text
                  flex={1}
                  color="grayBlue.50"
                  fontSize={14}
                  fontWeight={700}
                >
                  Persons representing the company (those actually using the
                  accounts with Gemba) must undergo full identification via
                  Gemba's onboarding portal (including the liveness checks)
                </Text>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </form>
    </>
  );
};

// export const PersonalAccountFormPage = () => {
//   return (
//     <Box px={8}>
//       <Text fontSize="4xl" fontWeight={700} mt={4}>
//         Personal Account Application
//       </Text>

//       <Divider my={4} />

//       <Box>
//         <PersonalAccountForm />
//       </Box>
//     </Box>
//   );
// };
