import { defineStyleConfig } from "@chakra-ui/react";

export const Button = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    border: "2px",
    borderColor: "transparent",
    borderRadius: 16,
    fontWeight: "bold",
    // textTransform: "uppercase",
    // borderRadius: "base", // <-- border radius is same for all variants and sizes
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      // fontSize: "sm",
      px: 4, // <-- px is short for paddingLeft and paddingRight
      py: 3, // <-- py is short for paddingTop and paddingBottom
      // h: 8,
      fontSize: "12px",
    },
    md: {
      fontSize: "14px",
      px: 6, // <-- these values are tokens from the design system
      py: 2, // <-- these values are tokens from the design system
      // h: 8,
      borderRadius: 22,
    },
    lg: {
      fontSize: "md",
      px: 8, // <-- these values are tokens from the design system
      py: 6, // <-- these values are tokens from the design system
      // h: 10,
    },
  },
  // Two variants: outline and solid
  variants: {
    primary: {
      backgroundColor: "primary",
      color: "white",
      _hover: {
        bgColor: "white",
        color: "primary",
        borderColor: "primary",
      },
    },
    outline: {
      border: "2px solid",
      backgroundColor: "white",
      color: "grayBlue.50",
      borderColor: "grayBlue.97",
      _hover: {
        bgColor: "primary",
        color: "white",
      },
    },
    noBorder: {
      boxShadow: "0px 2px 5px rgba(38, 51, 77, 0.03)",
      _hover: {
        color: "primary",
      },
    },
    secondary: {
      bgColor: "rgba(51, 97, 255, 0.1)",
      color: "primary",
      _hover: {
        bgColor: "white",
        color: "primary",
        borderColor: "primary",
      },
    },
    gemba: {
      backgroundColor: "#2066E6",
      color: "white",
      _hover: {
        bgColor: "white",
        color: "#2066E6",
        borderColor: "#2066E6",
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "noBorder",
  },
});
