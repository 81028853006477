import { Icon } from "@chakra-ui/react";

export const UserIcon = (props: any) => (
  <Icon viewBox="0 0 12 12" {...props}>
    <path
      d="M10.3396 8.70408C9.83847 7.47959 8.88636 6.56122 7.7338 6.10204C8.38525 5.59184 8.78613 4.77551 8.78613 3.90816C8.78613 2.27551 7.53336 1 5.97992 1C4.42648 1 3.17371 2.27551 3.17371 3.85714C3.17371 4.72449 3.57459 5.54082 4.22604 6.05102C3.07348 6.5102 2.12138 7.42857 1.62027 8.65306C1.41982 9.16327 1.46993 9.77551 1.7706 10.2347C2.07127 10.7449 2.62249 11 3.22382 11H8.78613C9.38747 11 9.88858 10.6939 10.2394 10.2347C10.54 9.77551 10.5901 9.21429 10.3396 8.70408ZM4.17593 3.85714C4.17593 2.83673 4.9777 2.02041 5.97992 2.02041C6.98214 2.02041 7.78392 2.83673 7.78392 3.85714C7.78392 4.87755 6.98214 5.69388 5.97992 5.69388C4.9777 5.69388 4.17593 4.87755 4.17593 3.85714ZM9.38747 9.72449C9.23713 9.92857 9.03669 10.0816 8.78613 10.0816H3.17371C2.92315 10.0816 2.72271 9.97959 2.57237 9.72449C2.42204 9.52041 2.42204 9.31633 2.52226 9.11224C3.1236 7.63265 4.47659 6.71429 5.97992 6.71429C7.48325 6.71429 8.83625 7.63265 9.43758 9.06122C9.5378 9.26531 9.48769 9.52041 9.38747 9.72449Z"
      fill="currentColor"
    />
  </Icon>
);
