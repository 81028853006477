import { Icon } from "@chakra-ui/react";

export const ConvertIcon = (props: any) => (
  <Icon viewBox="0 0 21 20" {...props}>
    <path
      d="M10.4998 1.66669C5.89984 1.66669 2.1665 5.40002 2.1665 10C2.1665 14.6 5.89984 18.3334 10.4998 18.3334C15.0998 18.3334 18.8332 14.6 18.8332 10C18.8332 5.40002 15.0998 1.66669 10.4998 1.66669ZM10.4998 16.6667C6.82484 16.6667 3.83317 13.675 3.83317 10C3.83317 6.32502 6.82484 3.33335 10.4998 3.33335C14.1748 3.33335 17.1665 6.32502 17.1665 10C17.1665 13.675 14.1748 16.6667 10.4998 16.6667ZM13.9748 12.3L13.0582 11.3834C13.6498 10.275 13.4998 8.87502 12.5665 7.94169C11.9915 7.36669 11.2498 7.08335 10.4998 7.08335C10.4748 7.08335 10.4498 7.09169 10.4248 7.09169L11.3332 8.00002L10.4498 8.88335L8.0915 6.52502L10.4498 4.16669L11.3332 5.05002L10.5332 5.85002C11.5915 5.85835 12.6415 6.25002 13.4498 7.05002C14.8665 8.47502 15.0415 10.6834 13.9748 12.3ZM12.9082 13.475L10.5498 15.8334L9.6665 14.95L10.4582 14.1584C9.40817 14.15 8.35817 13.7417 7.55817 12.9417C6.13317 11.5167 5.95817 9.31669 7.02484 7.70002L7.9415 8.61669C7.34984 9.72502 7.49984 11.125 8.43317 12.0584C9.0165 12.6417 9.7915 12.925 10.5665 12.9L9.6665 12L10.5498 11.1167L12.9082 13.475Z"
      fill="currentColor"
    />
  </Icon>
);
