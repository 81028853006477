import { Box, Button, Flex, Spinner, Text, Textarea } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";

import { accountApi } from "../../../api/accountApi";
import { paymentApi } from "../../../api/paymentApi";
import { Account } from "../../Dashboard/Accounts";
import { AccountSelect } from "../common/AccountSelect";
import { FileUpload } from "../common/FileUpload";
import { PaymentDetails } from "../common/PaymentDetails";
import { SuccessCard } from "./SuccessCard";
import { ToAccountSelect } from "./ToAccountSelect";

export type InternalPaymentsFormData = {
  debitaccountid: string;
  creditaccountid: string;
  amount: string;
};

const initialData = {
  debitaccountid: "",
  creditaccountid: "",
  amount: "",
  paymentsfile: undefined,
};

export const InternalPaymentsForm = () => {
  const [showSuccessScreen, setSuccess] = useState(false);
  const { data: accounts, isLoading } = useQuery<Account[]>(
    ["accounts"],
    async () => {
      return accountApi.list();
    }
  );

  const mutation = useMutation(
    (payload: any) => {
      return paymentApi.createInternalPayment(payload);
    }
    // {
    //   onSuccess: () => {},
    // }
  );

  const initialValues = useMemo(() => {
    if (!accounts?.length) {
      return initialData;
    }

    return {
      ...initialData,
      debitaccountid: accounts[0].id,
    };
  }, [accounts]);

  if (!accounts?.length) {
    return <Text>Loading</Text>;
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors = {} as any;
        if (!values.debitaccountid) {
          errors.debitaccountid = "Required account";
        }
        if (!values.amount) {
          errors.amount = "Required amount";
        }
        if (!values.creditaccountid) {
          errors.creditaccountid = "Required account";
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await new Promise((r) => setTimeout(r, 2000));
        await mutation.mutate(values);
        setSubmitting(false);
        setSuccess(true);
        resetForm();
      }}
    >
      {({
        isSubmitting,
        handleSubmit,
        handleChange,
        values,
        errors,
        touched,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Flex>
              <Box width="100%" mr={8}>
                {showSuccessScreen && (
                  <SuccessCard
                    amount="€ 1,122,122.50"
                    accountId={values.debitaccountid}
                    onCreateNew={() => setSuccess(false)}
                  />
                )}

                {!showSuccessScreen && isSubmitting && (
                  <Flex h="100%" justifyContent="center" alignItems="center">
                    <Spinner
                      thickness="5px"
                      speed="0.65s"
                      emptyColor="grayBlue.90"
                      color="primary"
                      size="xl"
                    />
                  </Flex>
                )}

                {!showSuccessScreen && !isSubmitting && (
                  <>
                    <AccountSelect
                      accounts={accounts}
                      handleChange={handleChange}
                      accountFieldName="debitaccountid"
                      amountFieldName="amount"
                    />

                    {errors.debitaccountid && touched.debitaccountid ? (
                      <Text color="red">{errors.debitaccountid}</Text>
                    ) : null}
                    {errors.amount && touched.amount ? (
                      <Text color="red">{errors.amount}</Text>
                    ) : null}

                    <Box mb={12} />

                    <ToAccountSelect
                      accounts={accounts}
                      handleChange={handleChange}
                      accountFieldName="creditaccountid"
                    />

                    {errors.creditaccountid && touched.creditaccountid ? (
                      <Text color="red">{errors.creditaccountid}</Text>
                    ) : null}

                    <Flex mt={8}>
                      <Textarea
                        rows={3}
                        border="2px solid #F0F2F7"
                        boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
                        borderRadius={12}
                        placeholder="Add Payment details..."
                        mb={4}
                        mr={8}
                        w="60%"
                        bgColor="white"
                        resize="none"
                      ></Textarea>

                      <FileUpload
                        fileTypeLabel="(PDF/JPG/PNG)"
                        options={{ multiple: true }}
                        buttonLabel=""
                        boxProps={{ w: "40%" }}
                      />
                    </Flex>

                    <Flex justifyContent="flex-end" mt={4}>
                      <Button mr={8} size="md" variant="noBorder">
                        Save as Template
                      </Button>

                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                        px={32}
                      >
                        Create Payment
                      </Button>
                    </Flex>
                  </>
                )}
              </Box>
              {/* <Box width="40%">
                <PaymentDetails />
              </Box> */}
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};
