import { Box, Flex, IconButton, Text } from "@chakra-ui/react";

import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import { OutlinedIconButton, PrimaryButton } from "../../../components/Button";
import { DashboardCard } from "../../../components/Card";
import purse from "./img/purse-2.png";

export const AddCorporateAccountCard = () => {
  return (
    <DashboardCard
      bgImage={`url(${purse})`}
      backgroundRepeat="no-repeat"
      backgroundPosition="150px 30px"
      backgroundSize="120px"
      minW={310}
    >
      <Flex direction="column" height="100%">
        <Flex>
          <Text color="primary" fontSize={20} fontWeight={600} mr={8}>
            Add Corporate Account
          </Text>

          <OutlinedIconButton
            aria-label="Share"
            icon={<ShareIcon />}
          ></OutlinedIconButton>
        </Flex>

        <Text color="grayBlue.70" fontSize={12}>
          Open Corporate account
        </Text>

        <Box flex={1} />

        <PrimaryButton w="100%">Open Gemba Corporate account</PrimaryButton>
      </Flex>
    </DashboardCard>
  );
};
