import { AttachmentIcon, DeleteIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Spinner,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";

import { api } from "../../api/api";
import { EmojiIcon } from "../../assets/icons/Emoji";
import { ExpandIcon } from "../../assets/icons/ExpandIcon";
import { TemplateIcon } from "../../assets/icons/Template";
import { OutlinedIconButton } from "../../components/Button";

const MessageSchema = Yup.object().shape({
  subject: Yup.string()
    .max(160, "Subject is too long")
    .required("Subject is required"),
  text: Yup.string().required("Subject is required"),
});

export const NewMessageForm = ({ onClose }: any) => {
  const toast = useToast();

  const mutation = useMutation(({ subject, text }: any) => {
    return api.post("/api/post", {
      author: "user",
      subject,
      text,
    });
  });

  const formik = useFormik({
    initialValues: {
      subject: "",
      text: "",
    },
    validationSchema: MessageSchema,
    onSubmit: async (values) => {
      await new Promise((r) => setTimeout(r, 3000));
      await mutation.mutate(values);
      toast({
        title: "Message sent",
        status: "success",
        duration: 4000,
        position: "top",
        isClosable: true,
      });
      formik.resetForm();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        bgColor="#fff"
        borderRadius={16}
        boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
        position="relative"
      >
        {formik.isSubmitting && (
          <Flex
            h="100%"
            w="100%"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            zIndex={10}
          >
            <Spinner
              thickness="5px"
              speed="0.65s"
              emptyColor="grayBlue.90"
              color="primary"
              size="xl"
            />
          </Flex>
        )}
        <Box opacity={formik.isSubmitting ? "0.3" : undefined}>
          <Box p={6}>
            <Flex mb={4}>
              <Text fontWeight={700} color="grayBlue.50" flex={1}>
                New message
              </Text>
              {onClose && (
                <OutlinedIconButton
                  as={NavLink}
                  to="/messages/new"
                  border="none"
                  ariaLabel="Expand"
                  icon={<ExpandIcon />}
                  mr={4}
                />
              )}
              {onClose && (
                <OutlinedIconButton
                  onClick={onClose}
                  ariaLabel="Close"
                  icon={<SmallCloseIcon />}
                />
              )}
              {!onClose && (
                <OutlinedIconButton
                  as={NavLink}
                  to="/messages"
                  ariaLabel="Close"
                  icon={<SmallCloseIcon />}
                />
              )}
            </Flex>

            <FormControl
              isInvalid={Boolean(
                formik.touched.subject && formik.errors.subject
              )}
            >
              <Input
                bgColor="#fff"
                type="text"
                placeholder="Subject"
                maxLength={160}
                fontWeight={700}
                color="grayBlue.50"
                border="none"
                name="subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
              />
              {formik.touched.subject && formik.errors.subject && (
                <FormErrorMessage>{formik.errors.subject}</FormErrorMessage>
              )}
            </FormControl>

            <Divider my={4} />

            <FormControl
              isInvalid={Boolean(formik.touched.text && formik.errors.text)}
              mb={4}
            >
              <Textarea
                rows={8}
                resize="none"
                color="grayBlue.50"
                fontWeight={700}
                placeholder=""
                name="text"
                value={formik.values.text}
                onChange={formik.handleChange}
              />
              {formik.touched.text && formik.errors.text && (
                <FormErrorMessage>{formik.errors.text}</FormErrorMessage>
              )}
            </FormControl>
          </Box>
          <Flex
            justifyContent="space-between"
            p={4}
            bgColor="grayBlue.97"
            borderBottomLeftRadius={16}
            borderBottomRightRadius={16}
          >
            <IconButton
              aria-label="Delete"
              boxShadow="none"
              borderRadius={4}
              color="grayBlue.85"
              fontSize={20}
              icon={<DeleteIcon />}
              onClick={() => formik.resetForm()}
            />

            <Box>
              <IconButton
                aria-label="Use template"
                boxShadow="none"
                borderRadius={4}
                color="grayBlue.85"
                fontSize={26}
                icon={<TemplateIcon />}
                mr={2}
              />
              <IconButton
                aria-label="Emoji picker"
                boxShadow="none"
                borderRadius={4}
                color="grayBlue.85"
                fontSize={26}
                icon={<EmojiIcon />}
                mr={2}
              />
              <IconButton
                aria-label="Attach file"
                boxShadow="none"
                borderRadius={4}
                color="grayBlue.85"
                fontSize={24}
                icon={<AttachmentIcon />}
                mr={6}
              />

              <Button variant="secondary" type="submit">
                Send Now
              </Button>
            </Box>
          </Flex>
        </Box>
      </Box>
    </form>
  );
};
