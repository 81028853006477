import { Box } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { api } from "../../api/api";
import { TransactionsTable } from "../../components/TransactionsTable";

export const Transactions = () => {
  const { data = [], isLoading } = useQuery(["transactions"], async () => {
    const r = await api.get("/api/transactions");

    return r.data.slice(0, 3);
  });

  return (
    <Box p={8}>
      <TransactionsTable
        data={data}
        // selectedTxn={selectedTxn}
        // onRowClick={handleRowClick}
      />
    </Box>
  );
};
