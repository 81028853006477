import {
  Box,
  Divider,
  HStack,
  PinInput,
  PinInputField,
  Text,
  VStack,
} from "@chakra-ui/react";

export const PhoneVerifyContent = ({ onSubmit }: any) => {
  return (
    <Box>
      <Text fontSize="4xl" fontWeight={700} mt={4}>
        Verify phone number
      </Text>

      <Divider my={4} />

      <Box>
        <VStack>
          <Text fontSize="2xl" fontWeight={700} mt={4}>
            Enter code from SMS
          </Text>
          <HStack>
            <PinInput size="lg" onComplete={onSubmit}>
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};
