import { defineStyleConfig } from "@chakra-ui/react";

export const IconButton = defineStyleConfig({
  baseStyle: {},
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // Two variants: outline and solid
  variants: {
    primary: {
      border: "1px solid #fff",
      borderColor: "#fff",
      backgroundColor: "red",
      color: "red",
      _hover: {
        bgColor: "white",
        color: "primary",
        borderColor: "primary",
      },
    },
    outline: {
      // border: "2px solid",
      backgroundColor: "white",
      color: "grayBlue.50",
      borderColor: "grayBlue.97",
      _hover: {
        bgColor: "primary",
        color: "white",
      },
    },
    noBorder: {
      backgroundColor: "red",
      boxShadow: "0px 2px 5px rgba(38, 51, 77, 0.03)",
      _hover: {
        color: "primary",
      },
    },
    secondary: {
      bgColor: "rgba(51, 97, 255, 0.1)",
      color: "#2066E6",
      _hover: {
        bgColor: "white",
        color: "primary",
        borderColor: "primary",
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    // size: "md",
    variant: "primary",
  },
});
