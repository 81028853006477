import { InfoOutlineIcon, SunIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text } from "@chakra-ui/react";

import pattern from "./pattern.png";

export const Card = ({ card, selected, onClick }: any) => {
  return (
    <Flex
      minW={311}
      h={88}
      direction="column"
      borderRadius={18}
      backgroundImage={`url(${pattern})`}
      backgroundColor="#000"
      cursor="pointer"
      borderWidth={3}
      borderColor={selected ? "primary" : "transparent"}
      onClick={onClick}
    >
      <Flex justifyContent="flex-end" p={2}>
        <Box mr={2} pt={3}>
          <Text fontSize="16px" fontWeight={700} color="#fff">
            {card.number}
          </Text>
          <Text fontSize="12px" color="#fff" fontWeight={700} opacity="0.8">
            {card.name} | {card.type}
          </Text>
        </Box>
        <Box>
          <IconButton
            w="24px"
            h="24px"
            minW="auto"
            borderRadius="50%"
            colorScheme="blue"
            variant="outline"
            aria-label="Info"
            icon={<InfoOutlineIcon />}
            mr={2}
            bgColor="#fff"
          />
          <IconButton
            w="24px"
            h="24px"
            minW="auto"
            borderRadius="50%"
            colorScheme="blue"
            variant="outline"
            aria-label="Info"
            icon={<SunIcon />}
            bgColor="#fff"
          />
        </Box>
      </Flex>
    </Flex>
  );
};
