import { Box } from "@chakra-ui/react";
import { useTheme } from "providers/theme/ThemeProvider";
import { NavLink } from "react-router-dom";

import gembaLogo from "../../assets/logo.svg";
import { CenterMenu } from "./CenterMenu";
import { RightMenu } from "./RightMenu";

export const Header = () => {
  const { logo, favicon, colors } = useTheme();

  return (
    <Box display="flex" px={6} py={3} borderBottom="2px solid #f0f2f7">
      <NavLink
        to="/"
        style={{
          display: "flex",
          padding: "8px",
          backgroundColor: colors.logoBg || undefined,
        }}
      >
        <img
          src={logo || favicon || gembaLogo}
          alt="logo"
          style={{ height: "32px" }}
        />
      </NavLink>
      <CenterMenu />
      <RightMenu />
    </Box>
  );
};
