import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { MdInfoOutline, MdOutlinePersonOutline } from "react-icons/md";

import { formatBalance } from "../../utils/formatters";

export const CardInfo = ({ card }: any) => {
  const CardRow = ({ label, amount }: any) => {
    return (
      <Flex justifyContent="space-between" mb={4}>
        <Text color="grayBlue.70" fontSize={14} fontWeight={500}>
          {label}
        </Text>
        <Text color="grayBlue.50" fontSize={14} fontWeight={500}>
          {formatBalance(amount, card.currency)}
        </Text>
      </Flex>
    );
  };

  return (
    <Box>
      <Flex justifyContent="space-between" mb={4}>
        <Flex alignItems="center">
          <Text fontSize={20} mr={2} color="grayBlue.70">
            <MdOutlinePersonOutline />
          </Text>
          <Text fontSize={14} fontWeight={500} color="grayBlue.70">
            Alexander Legoshin - Personal EUR
          </Text>
        </Flex>
      </Flex>

      <Flex>
        <Text fontSize={16} fontWeight={600} color="grayBlue.70" mr={2} mb={4}>
          Card details
        </Text>
        <Text fontSize={16} fontWeight={500} color="grayBlue.70">
          <MdInfoOutline />
        </Text>
      </Flex>

      <CardRow label="Total daily limit" amount={2804.8} />
      <CardRow label="Posted" amount={0.8} />
      <CardRow label="Pending" amount={0.8} />
      <CardRow label="Unavailable" amount={-2804.8} />
      <CardRow label="Available to spend" amount={0} />

      <Text color="grayBlue.70" fontSize={14} fontWeight={500} mb={4}>
        Billing address
      </Text>

      <Text color="grayBlue.50" fontSize={14} fontWeight={500} mb={4}>
        Gemba Finance Ltd. Level 39, 1 Canada Square, Canary Wharf, London, E14
        5AB, UK
      </Text>

      <CardRow label="Daily spend limit" amount={4000} />

      <Button
        backgroundColor="white"
        color="grayBlue.50"
        border="2px"
        borderColor="grayBlue.97"
        borderRadius={16}
        fontSize={12}
        h={8}
        _hover={{
          bgColor: "primary",
          color: "white",
        }}
      >
        Card Transactions
      </Button>
    </Box>
  );
};
