import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, IconButton, Text } from "@chakra-ui/react";

export const EmptyCard = () => {
  return (
    <Flex
      minW={211}
      h={88}
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
      borderRadius={18}
      border="1px solid #E6EAF2"
      color="#fff"
    >
      <Button
        variant="primary"
        // bgColor="#2066E6"
        // ml={4}
        // mr={1}
        // borderRadius={16}
        // colorScheme="primary"
        // px={4}
        // h={8}
      >
        <Text mr={2}>Get card</Text>
        <AddIcon h="14px" w="14px" />
      </Button>
    </Flex>
  );
};
