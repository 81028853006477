import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  PinInput,
  PinInputField,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AuthContext } from "auth/AuthProvider";
import { useTheme } from "providers/theme/ThemeProvider";
import { useContext, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { NavLink, Navigate } from "react-router-dom";

export function SignupPage() {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const { onSignup, token } = useContext(AuthContext);
  const { logo } = useTheme();

  const handleEmailSubmit = () => {
    setEmailSubmitted(true);
  };

  if (token) {
    console.log({ token });
    return <Navigate to="/" replace />;
  }

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg="gray.50">
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <img
            // src="https://my.ge.mba/themes/default/img/logo.svg"
            src={logo || "https://my.ge.mba/themes/default/img/logo.svg"}
            alt="logo"
            style={{ maxHeight: "80px", minHeight: "40px" }}
          />
          {/* <Heading fontSize={"4xl"}>Welcome</Heading> */}
        </Stack>
        <Box rounded={"lg"} bg="white" boxShadow={"lg"} p={8} minW={400}>
          <Stack spacing={4}>
            {emailSubmitted ? (
              <Box>
                <VStack>
                  <Text fontSize="2xl" fontWeight={700} mt={4}>
                    Enter code from email
                  </Text>
                  <HStack>
                    <PinInput size="lg" otp onComplete={onSignup}>
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                </VStack>
              </Box>
            ) : (
              <>
                <FormControl id="email" isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" />
                </FormControl>

                <Stack spacing={10} pt={2}>
                  <Button
                    variant="primary"
                    size="md"
                    onClick={handleEmailSubmit}
                  >
                    Sign up
                  </Button>
                  <Button size="md" onClick={onSignup}>
                    <FcGoogle />
                    <Text ml={6}>Sign up with Google</Text>
                  </Button>
                </Stack>
              </>
            )}
            <Stack pt={6}>
              <Text align={"center"}>
                Already a user?{" "}
                <NavLink color="primary" to="/login">
                  Login
                </NavLink>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
