import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex } from "@chakra-ui/react";
import { useCounter } from "@react-hookz/web";
import { useQuery } from "@tanstack/react-query";

import { api } from "../../api/api";
import { OutlinedIconButton } from "../../components/Button";
import { TransactionsTable } from "../../components/TransactionsTable";
import { Header } from "./Header";

const LIMIT = 10;

export const TransactionsPage = () => {
  const [page, counterActions] = useCounter(0, 1000, 0);

  const { data = [], isLoading } = useQuery(["transactions"], async () => {
    const r = await api.get("/api/transactions");

    return r.data;
  });

  const transactions = data.slice(page * LIMIT, (page + 1) * LIMIT);

  return (
    <Box px={8} pb={6}>
      <Header />

      <TransactionsTable
        data={transactions}
        // selectedTxn={selectedTxn}
        // onRowClick={handleRowClick}
      />
      <Flex justifyContent="flex-end" alignItems="center" mt={6}>
        {/* <Text mr={2} fontSize={16} fontWeight={500} color="grayBlue.80">
          1-1 of 1
        </Text> */}
        <Box>
          <OutlinedIconButton
            aria-label="Previous"
            icon={<ChevronLeftIcon />}
            mr={2}
            onClick={() => counterActions.dec()}
            disabled={page < 1}
          />
          <OutlinedIconButton
            aria-label="Next"
            icon={<ChevronRightIcon />}
            onClick={() => counterActions.inc()}
            disabled={data.length <= (page + 1) * LIMIT}
          />
        </Box>
      </Flex>
    </Box>
  );
};
