import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext<any>(null);

const fakeAuth = () =>
  new Promise<string>((resolve) => {
    setTimeout(() => resolve("2342f2f1d131rf12"), 250);
  });

const defaultUserValidated = true;

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const [userValidated, setUserValidated] = useState(defaultUserValidated);
  const [userData, setUserData] = useState({
    validated: false,
  });

  const handleLogin = async () => {
    const token = await fakeAuth();

    sessionStorage.setItem("token", JSON.stringify(token));
    setUserValidated(true);
    setToken(token);

    navigate("/");
  };

  const handleSignup = async () => {
    const token = await fakeAuth();

    sessionStorage.setItem("token", JSON.stringify(token));
    setToken(token);
    setUserValidated(false);

    navigate("/");
  };

  const handleLogout = () => {
    sessionStorage.setItem("token", "");
    setToken("");
  };

  const value = {
    token,
    userValidated,
    // userData,
    // setUserData,
    onLogin: handleLogin,
    onLogout: handleLogout,
    onSignup: handleSignup,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const value = useContext(AuthContext);

  return value;
};
