import { Box } from "@chakra-ui/react";
import { useState } from "react";

import { PersonalAccountForm } from "./PersonalAccountFormContent";
import { PhoneVerifyContent } from "./PhoneVerifyContent";
import { SumsubValidationContent } from "./SumsubValidationContent";

export const OnboardingPage = () => {
  const [step, setStep] = useState(0);

  return (
    <Box px={8}>
      <Box>
        {step === 0 && <PersonalAccountForm onSubmit={() => setStep(1)} />}
        {step === 1 && <PhoneVerifyContent onSubmit={() => setStep(2)} />}
        {step === 2 && <SumsubValidationContent />}
      </Box>
    </Box>
  );
};
