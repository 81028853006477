import {
  AddIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  RepeatIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Select,
  Text,
  VStack,
  useClipboard,
} from "@chakra-ui/react";
import { useCounter } from "@react-hookz/web";
import { useQuery } from "@tanstack/react-query";
import { Statement, StatementRecord, accountApi } from "api/accountApi";
import { api } from "api/api";
import { ConvertIcon } from "assets/icons/Convert";
import { CopyIcon } from "assets/icons/Copy";
import { EditIcon } from "assets/icons/Edit";
import { MoreIcon } from "assets/icons/More";
import { PaymentIcon } from "assets/icons/Payment";
import { ShareIcon } from "assets/icons/Share";
import { UserIcon } from "assets/icons/User";
import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { formatBalance } from "utils/formatters";

import { OutlinedIconButton } from "components/Button";
import { TransactionsTable } from "components/TransactionsTable";

import { Header } from "../Header";
import { TransactionInfo } from "./TransactionInfo";
import { TransactionNotes } from "./TransactionNotes";

const MoreMenuItem = () => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="secondary"
        size="sm"
        mr={4}
        color="grayBlue.50"
        bgColor="grayBlue.97"
      >
        More <MoreIcon ml={2} fontSize={18} />
      </MenuButton>
      <MenuList
        borderRadius={16}
        fontSize={14}
        fontWeight={700}
        color="#7D8FB3"
        boxShadow="0px 30px 60px rgba(0, 0, 0, 0.2)"
        zIndex={10}
      >
        <MenuItem>Referrals</MenuItem>
        <MenuDivider />

        <MenuItem>Payments</MenuItem>
        <MenuDivider />
        <MenuItem>Switch account</MenuItem>
      </MenuList>
    </Menu>
  );
};

const LIMIT = 4;

export const SingleTransactionPage = () => {
  const { transactionId } = useParams();

  const { onCopy, value, setValue, hasCopied } = useClipboard("");

  const { data, isLoading } = useQuery(
    ["transactions", transactionId],
    async () => {
      const { data: txn } = await api.get(`/api/transactions/${transactionId}`);
      const statement = await accountApi.getStatement(txn.accountId);

      setValue(statement?.balance.iban);

      return {
        statement,
        transaction: txn,
      };
    }
  );

  if (!data?.statement) {
    return null;
  }

  const { statement, transaction } = data;

  return (
    <Box px={8} pt={4}>
      <Text fontSize="4xl" fontWeight={500} mb={8}>
        Personal account
      </Text>

      <Box mb={4}>
        <Flex justifyContent="space-between">
          <Box>
            <Badge mb={2}>{statement.balance.currency}</Badge>
            <Text color="#969696" fontSize={16} fontWeight={500}>
              The euro (symbol: €; code: EUR)
            </Text>
          </Box>
          <OutlinedIconButton
            aria-label="Share"
            icon={<ShareIcon />}
          ></OutlinedIconButton>
        </Flex>
        <Text fontSize={48} color="primary">
          {formatBalance(
            statement.balance.closing.balance,
            statement.balance.currency
          )}
        </Text>
        <Text color="grayBlue.70" fontSize={16} fontWeight={500} mb={4}>
          {formatBalance(
            statement.balance.opening.balance,
            statement.balance.currency
          )}
        </Text>

        <Flex mb={4}>
          <Button variant="primary" size="sm" mr={4}>
            Pay <PaymentIcon ml={2} fontSize={18} />
          </Button>
          <Button variant="primary" size="sm" mr={4}>
            Add money <AddIcon ml={2} fontSize={12} />
          </Button>
          <Button variant="primary" size="sm" mr={4}>
            Convert <ConvertIcon ml={2} fontSize={18} />
          </Button>
          <MoreMenuItem />
        </Flex>

        <Flex alignItems="center">
          <UserIcon color="grey.70" />
          <Text
            textTransform="capitalize"
            mx={1.5}
            fontSize={14}
            fontWeight={500}
            color="grey.70"
          >
            Personal
          </Text>
          <EditIcon color="grey.70" mr={4} />

          <Text
            textTransform="capitalize"
            mr={2}
            fontSize={14}
            fontWeight={700}
            color="grey.70"
          >
            {statement.balance.iban}
          </Text>
          <CopyIcon
            color="grey.70"
            fontSize={13}
            onClick={onCopy}
            cursor="pointer"
          />
        </Flex>
      </Box>

      <Divider my={6} />

      <Flex>
        <VStack align="flex-start">
          <Text color="grayBlue.70" fontWeight={500} fontSize={12}>
            Amount
          </Text>
          <Text color="grayBlue.50" fontWeight={500} fontSize={48}>
            {formatBalance(transaction.amount, transaction.currency)}
            {/* EUR 2,804.80 */}
          </Text>

          <Link
            as={NavLink}
            to={`/accounts/${transaction.accountId}`}
            color="primary"
            fontWeight={500}
            fontSize={13}
          >
            View All transactions
          </Link>
        </VStack>
      </Flex>

      <Divider my={6} />
      <Flex>
        <Box w="50%">
          <TransactionInfo record={transaction} />
        </Box>
        <Box p={8} w="50%">
          <TransactionNotes />
        </Box>
      </Flex>

      <Divider my={6} />

      <Flex justifyContent="flex-end" mb={6}>
        <Button variant="primary">Apply changes</Button>
      </Flex>
    </Box>
  );
};
