import { UpDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import { Divider } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useField, useFormikContext } from "formik";
import CurrencyInput from "react-currency-input-field";

import { Statement, accountApi } from "../../../api/accountApi";
import { ReactComponent as SwapIcon } from "../../../assets/icons/swap.svg";
import { Badge } from "../../../components/Badge";
import { formatAccountName, formatBalance } from "../../../utils/formatters";
import { Account } from "../../Dashboard/Accounts";

type Props = {
  accounts: Account[];
  selectedAccountId?: string;
  handleChange: any;
  accountFieldName: string;
  amountFieldName: string;
};

export const AccountSelect = ({
  accounts,
  handleChange,
  accountFieldName,
  amountFieldName,
}: Props) => {
  const { values, touched, setFieldValue } = useFormikContext();

  const [{ value: selectedAccountId }] = useField(accountFieldName);
  const [{ value: amountValue }] = useField(amountFieldName);
  console.log({ values });
  console.log({ amountValue });

  const amount = Number.parseFloat(amountValue || "0");
  const fee = 1000;

  const { data: statement, isLoading: isStatementLoading } =
    useQuery<Statement>(
      ["account-select", "statement", selectedAccountId],
      async () => {
        return accountApi.getStatement(selectedAccountId);
      },
      {
        enabled: !!selectedAccountId,
      }
    );

  return (
    <Box p={8} border="1px solid #CFD6E6;" borderRadius={6}>
      <Select
        // placeholder="Payment account"
        name={accountFieldName}
        size="md"
        mb={4}
        bgColor="#fff"
        border="none"
        value={selectedAccountId}
        onChange={handleChange}
      >
        {accounts.map((acc) => {
          return (
            <option key={acc.id} value={acc.id}>
              {formatAccountName(acc)}
            </option>
          );
        })}
      </Select>

      <InputGroup>
        {statement && (
          <InputLeftElement w="84px">
            <Badge text={statement?.balance.currency} />
          </InputLeftElement>
        )}
        <CurrencyInput
          style={{
            backgroundColor: "#fff",
            fontSize: "20px",
            fontWeight: 500,
            width: "100%",
            padding: "5px",
            paddingLeft: selectedAccountId ? "72px" : "16px",
          }}
          id="input-example"
          name={amountFieldName}
          value={amountValue}
          // placeholder="Amount"
          disabled={!selectedAccountId}
          // defaultValue={0}
          decimalsLimit={2}
          onValueChange={(value, name) => {
            console.log(name);
            console.log(value);
            setFieldValue(amountFieldName, value);
          }}
        />
      </InputGroup>

      <Text fontWeight={500} fontSize={18} color="#7D8FB3" ml={2}>
        {statement
          ? formatBalance(
              statement.balance.closing.balance,
              statement.balance.currency
            )
          : "-"}
      </Text>
      <Text fontWeight={500} fontSize={10} color="#B8C3D9" ml={2}>
        Available in your account
      </Text>

      {statement && (
        <>
          <Divider my={8} mx={1} />
          <Flex gap={16}>
            <Box display="flex" flexDirection="column">
              <Text fontWeight={600} fontSize={18} color="darkGreen.5">
                {/* € 1,200.00 */}
                {formatBalance(fee, statement.balance.currency)}
              </Text>
              <Text fontWeight={500} fontSize={10} color="grayBlue.85">
                fee
              </Text>
            </Box>

            <Box display="flex" flexDirection="column">
              <Text fontWeight={600} fontSize={18} color="black">
                {/* € 1,122,122.50 */}
                {formatBalance(amount + fee, statement.balance.currency)}
              </Text>
              <Text fontWeight={500} fontSize={10} color="grayBlue.85">
                Total amount with fee
              </Text>
            </Box>
            {/* <Box display="flex" justifyContent="center">
              <Button
                bgColor="primary"
                ml={2}
                borderRadius={16}
                colorScheme="blue"
                px={4}
                h={8}
              >
                <Text mr={2} fontSize={12}>
                  Swap
                </Text>
                <SwapIcon fontSize={12} />
              </Button>
            </Box> */}
          </Flex>
        </>
      )}
    </Box>
  );
};
