import { Box, Divider, Flex, Spinner, Text } from "@chakra-ui/react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useQuery } from "@tanstack/react-query";

import { api } from "../../api/api";

// const user = {
//   token: "_act-sbx-425c2c14-525f-4a7b-ab49-f1d85286c0ae",
//   userId: "random-postman-user-yuleq0ixq",
// };

const accessTokenExpirationHandler = (...args) => console.log({ args });

const config = {
  lang: "en", //language of WebSDK texts and comments (ISO 639-1 format)
  // email: applicantEmail,
  // phone: applicantPhone,
  // i18n: customI18nMessages, //JSON of custom SDK Translations
  uiConf: {
    customCssStr: `
    @font-face {
      font-family: "PT Root";
      src: local("PT Root"),
        url(http://localhost:3000/static/media/PT_Root_UI_Bold.e988a8b4ef7438fa6fdb.otf)
          format("opentype");
      font-style: bold;
      font-weight: bold;
    }
    @font-face {
      font-family: "PT Root";
      src: local("PT Root"),
        url(http://localhost:3000/static/media/PT%20Root%20UI_Regular.5cbbba71bf05286a5047.otf)
          format("opentype");
      font-style: normal;
      font-weight: normal;
    }
    :root {
      --primary-color: #2066e6;
      --success-color: #29cc39;
    }
    body {
      font-family: PT Root, Montserrat, Helvetica, Arial, sans-serif;
    }
    button.submit {
      background: none;
      background-color: var(--primary-color);
      color: #ffffff;
      border: 2px solid transparent;
    }
    button.blue {
      background: var(--primary-color);
      color: var(--white-color);
    }
    button.back {
      border: 2px solid transparent;
    }
    button:hover,
    button.blue:hover,
    button.submit:hover {
      background-color: #ffffff;
      border: 2px solid var(--primary-color);
      color: var(--primary-color);
      background-image: none !important;
      transform: none !important;
      box-shadow: none !important;
    }
    .step.active .bullet:before,
    .step.active.success .bullet:before {
      background-color: var(--primary-color) !important;
    }
    .step.active .line,
    .step.success .line {
      background-color: var(--primary-color) !important;
    }
    .radio-item .checkmark:after {
      background-color: var(--primary-color) !important;
    }
    button:active:not(:disabled):not(.disabled),
    button:hover:not(:disabled):not(.disabled):not(:active) {
      box-shadow: none !important;
      transform: none !important;
      -webkit-transform: none !important;
    }
    /* loader */
    .round-icon {
      background-color: var(--primary-color) !important;
      background-image: none !important;
    }
    span.arrow {
      display: none;
    }
    
    `,
    // ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}",

    // customCssStr:
    //   "button.submit, button[type=submit]: {background-image: none;color: #fff;background-color: #2066E6;}",
    //     customCss: "https://url.com/styles.css"
    //     // URL to css file in case you need change it dynamically from the code
    //     // the similar setting at Customizations tab will rewrite customCss
    //     // you may also use to pass string with plain styles `customCssStr:`
  },
};

const options = { addViewportTag: false, adaptIframeHeight: true };

export const SumsubValidationContent = () => {
  const { data, isLoading, isError } = useQuery<any>(
    ["sumsub-user"],
    async () => {
      const res = await api.get("/api/sumsub/token");
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 600,
    }
  );

  console.log({ data });

  return (
    <Box>
      <Text fontSize="4xl" fontWeight={700} mt={4}>
        Identity Verification
      </Text>

      <Divider my={4} />

      <Box>
        {isLoading && (
          <Flex justifyContent="center" p={8}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="primary"
              size="xl"
            />
          </Flex>
        )}
        {data && (
          <SumsubWebSdk
            accessToken={data.token}
            expirationHandler={accessTokenExpirationHandler}
            config={config}
            options={options}
            // onMessage={messageHandler}
            // onError={errorHandler}
          />
        )}
      </Box>
    </Box>
  );
};
