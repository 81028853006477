import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { useMemo } from "react";

import { accountApi } from "../../../api/accountApi";
import { ReactComponent as AtIcon } from "../../../assets/icons/at.svg";
import { ReactComponent as BankIcon } from "../../../assets/icons/bank.svg";
import { ReactComponent as DashIcon } from "../../../assets/icons/dash.svg";
import { ReactComponent as HomeIcon } from "../../../assets/icons/home.svg";
import { ReactComponent as PublicIcon } from "../../../assets/icons/public.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { Badge } from "../../../components/Badge";
import { Account } from "../../Dashboard/Accounts";
import { AccountSelect } from "../common/AccountSelect";
import { FormTitle } from "../common/FormTitle";
import { PaymentDetails } from "../common/PaymentDetails";

const initialData = {
  debitaccountid: "",
  amount: "0",
  extaccountnumber: "",
  benefname: "",
  registrationnumber: "",
  benefcountry: "",
  benefaddress: "",
  extbankswift: "",
  extbankname: "",
  extbenefbankcountry: "",
  extbankaddress: "",
  benefemail: "",
  paymentsfile: undefined,
};

export type InternationalPaymentsFormData = typeof initialData;

export const InternationalPaymentsForm = () => {
  const { data: accounts, isLoading } = useQuery<Account[]>(
    ["accounts"],
    async () => {
      return accountApi.list();
    }
  );

  const initialValues = useMemo(() => {
    if (!accounts?.length) {
      return initialData;
    }

    return {
      ...initialData,
      debitaccountid: accounts[0].id,
    };
  }, [accounts]);

  if (!accounts?.length) {
    return <Text>Loading</Text>;
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        console.log({ values });
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({ isSubmitting, handleSubmit, handleChange, values }) => (
        <Form onSubmit={handleSubmit}>
          <Flex>
            <Box width="60%" mr={8}>
              <AccountSelect
                accounts={accounts}
                handleChange={handleChange}
                accountFieldName="debitaccountid"
                amountFieldName="amount"
              />

              <Box mb={12} />

              <FormTitle>Beneficiary Info</FormTitle>
              <Divider my={8} />

              <Stack spacing={8}>
                <InputGroup>
                  <InputLeftAddon
                    pointerEvents="none"
                    bgColor="#fff"
                    color="grayBlue.50"
                    children={
                      <>
                        <Badge text="IBAN" />
                        <Text>Beneficiary account (IBAN)* :</Text>
                      </>
                    }
                  />
                  <Input
                    bgColor="#fff"
                    type="text"
                    placeholder="GB43GEEI00993821000010"
                    name="extaccountnumber"
                    value={values.extaccountnumber}
                    onChange={handleChange}
                  />
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon
                    pointerEvents="none"
                    bgColor="#fff"
                    color="grayBlue.50"
                    children={
                      <>
                        <UserIcon width={20} height={20} />
                        <Text ml={2}>Beneficiary Name* :</Text>
                      </>
                    }
                  />
                  <Input
                    bgColor="#fff"
                    type="text"
                    placeholder="Alexander Legoshin"
                    name="benefname"
                    value={values.benefname}
                    onChange={handleChange}
                  />
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon
                    pointerEvents="none"
                    bgColor="#fff"
                    color="grayBlue.50"
                    children={
                      <>
                        <DashIcon width={20} height={20} />
                        <Text ml={2}>Registration Number*:</Text>
                      </>
                    }
                  />
                  <Input
                    bgColor="#fff"
                    type="text"
                    placeholder="000000000000000"
                    name="registrationnumber"
                    value={values.registrationnumber}
                    onChange={handleChange}
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon
                    pointerEvents="none"
                    bgColor="#fff"
                    color="grayBlue.50"
                    children={
                      <>
                        <PublicIcon width={20} height={20} />
                        <Text ml={2}>Beneficiary Country* :</Text>
                      </>
                    }
                  />
                  <Input
                    type="text"
                    bgColor="#fff"
                    // placeholder="Country"
                    name="benefcountry"
                    value={values.benefcountry}
                    onChange={handleChange}
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon
                    pointerEvents="none"
                    bgColor="#fff"
                    color="grayBlue.50"
                    children={
                      <>
                        <HomeIcon width={20} height={20} />
                        <Text ml={2}>Beneficiary Address* :</Text>
                      </>
                    }
                  />
                  <Input
                    type="text"
                    bgColor="#fff"
                    placeholder="Address"
                    name="benefaddress"
                    value={values.benefaddress}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Stack>

              <FormTitle mt={12}>Beneficiary Bank Info</FormTitle>

              <Divider my={8} />

              <Stack spacing={8} mb={8}>
                <InputGroup>
                  <InputLeftAddon
                    pointerEvents="none"
                    bgColor="#fff"
                    color="grayBlue.50"
                    children={
                      <>
                        <Badge text="SWIFT" bgColor="primary" />
                        <Text>SWIFT Code * :</Text>
                      </>
                    }
                  />
                  <Input
                    bgColor="#fff"
                    type="text"
                    placeholder="BUKBGB22"
                    name="extbankswift"
                    value={values.extbankswift}
                    onChange={handleChange}
                  />
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon
                    pointerEvents="none"
                    bgColor="#fff"
                    color="grayBlue.50"
                    children={
                      <>
                        <BankIcon width={20} height={20} />
                        <Text ml={2}>Bank Name :</Text>
                      </>
                    }
                  />
                  <Input
                    bgColor="#fff"
                    type="text"
                    placeholder="Leipziger Volksbank eG"
                    name="extbankname"
                    value={values.extbankname}
                    onChange={handleChange}
                  />
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon
                    pointerEvents="none"
                    bgColor="#fff"
                    color="grayBlue.50"
                    children={
                      <>
                        <PublicIcon width={20} height={20} />
                        <Text ml={2}>Beneficiary Country* :</Text>
                      </>
                    }
                  />
                  <Input
                    type="text"
                    bgColor="#fff"
                    placeholder="Country"
                    name="extbenefbankcountry"
                    value={values.extbenefbankcountry}
                    onChange={handleChange}
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon
                    pointerEvents="none"
                    bgColor="#fff"
                    color="grayBlue.50"
                    children={
                      <>
                        <HomeIcon width={20} height={20} />
                        <Text ml={2}>Beneficiary Address* :</Text>
                      </>
                    }
                  />
                  <Input
                    type="text"
                    bgColor="#fff"
                    placeholder="Address"
                    name="extbankaddress"
                    value={values.extbankaddress}
                    onChange={handleChange}
                  />
                </InputGroup>

                <Divider my={8} />

                <InputGroup>
                  <InputLeftAddon
                    pointerEvents="none"
                    bgColor="#fff"
                    color="grayBlue.50"
                    children={
                      <>
                        <AtIcon width={28} height={28} />
                        <Text ml={2}>Notify recipient by email :</Text>
                      </>
                    }
                  />
                  <Input
                    type="email"
                    bgColor="#fff"
                    placeholder="Address"
                    name="benefemail"
                    value={values.benefemail}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Stack>

              <Divider mb={8} />

              <Flex justifyContent="flex-end" mt={4}>
                <Button mr={8} size="md" variant="noBorder">
                  Save as Template
                </Button>

                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  px={32}
                >
                  Create Payment
                </Button>
              </Flex>
            </Box>
            <Box width="40%">
              <PaymentDetails />
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
