import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { AuthContext } from "auth/AuthProvider";
import { useTheme } from "providers/theme/ThemeProvider";
import { useContext } from "react";
import { NavLink } from "react-router-dom";

export function LoginPage() {
  const { onLogin } = useContext(AuthContext);
  const { logo, favicon, colors } = useTheme();

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <div
            style={{
              padding: "8px",
              backgroundColor: colors.logoBg || undefined,
            }}
          >
            <img
              // src="https://my.ge.mba/themes/default/img/logo.svg"
              src={
                logo ||
                favicon ||
                "https://my.ge.mba/themes/default/img/logo.svg"
              }
              alt="logo"
              style={{ maxHeight: "80px", minHeight: "40px" }}
            />
          </div>
          {/* <Heading fontSize={"4xl"}>Welcome</Heading> */}
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Link color={"primary"}>Forgot password?</Link>
              </Stack>
              <Button variant="primary" size="md" onClick={onLogin}>
                Sign in
              </Button>
            </Stack>
            <Stack pt={6}>
              <Text align={"center"}>
                Not a user?{" "}
                <NavLink color="primary" to="/sign-up">
                  Sign up
                </NavLink>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
