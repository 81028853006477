import { Account } from "../pages/Dashboard/Accounts";

export function formatBalance(balance: number, currency: string) {
  const options = { style: "currency", currency };
  const numberFormat = new Intl.NumberFormat("en-US", options);
  const parts = numberFormat.formatToParts(balance);
  const symbol = parts.shift();

  return `${symbol?.value} ${parts.map((p) => p.value).join("")}`;
}

export function formatAccountName(account: Account) {
  return `${account.iban} ${account.name}, ${account.type} account`;
}

export function formatFileSize(bytes, decimalPoint = 0) {
  if (bytes === 0) return "0 Bytes";
  var k = 1000,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint)) + " " + sizes[i]
  );
}
