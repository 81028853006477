import { CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Flex,
  IconButton,
  Link,
  Text,
  Textarea,
} from "@chakra-ui/react";

import { FileUpload } from "./FileUpload";

export const PaymentDetails = () => {
  return (
    <Box
      borderRadius={16}
      boxShadow="0px 60px 120px rgba(38, 51, 77, 0.1);"
      display="flex"
      flexDirection="column"
    >
      <Box bgColor="#FAFBFC" borderRadius="16px 16px 0px 0px" p={4}>
        <Text fontSize={18} fontWeight={700}>
          Payment details
        </Text>
      </Box>
      <Box bgColor="#fff" p={4} borderRadius=" 0px 0px 16px 16px">
        <Flex alignItems="center" mb={4}>
          <Badge
            bgColor="primary"
            color="#fff"
            borderRadius={16}
            fontSize={12}
            fontWeight={800}
            px={4}
            py={1}
            mr={4}
          >
            EUR
          </Badge>
          <Text fontSize={22} fontWeight={500} flex={1}>
            € 1,122,122.50
          </Text>

          <IconButton
            w="32px"
            h="32px"
            minW="auto"
            borderRadius="50%"
            // colorScheme="blue"
            variant="outline"
            aria-label="Info"
            icon={<CopyIcon />}
            mr={2}
            bgColor="#fff"
          />
          <IconButton
            w="32px"
            h="32px"
            minW="auto"
            borderRadius="50%"
            // colorScheme="blue"
            variant="outline"
            aria-label="Info"
            icon={<ExternalLinkIcon />}
            mr={2}
            bgColor="#fff"
          />
        </Flex>
        <Flex direction="column" pl={16}>
          <Flex alignItems="center" mb={1}>
            <Text fontSize={16} fontWeight={500}>
              Gemba (UK) ••00012
            </Text>
            <Badge
              ml={4}
              bgColor="#29CC39"
              color="#fff"
              borderRadius={16}
              fontSize={12}
              fontWeight={800}
              px={4}
              py={0.5}
              mr={4}
              textTransform="none"
            >
              Complete
            </Badge>
          </Flex>
          <Text color="#B8C3D9" fontSize={10} fontWeight={500} mb={4}>
            Sep 22 @ 11:02 PM @ 12:02 CET
          </Text>
          <Flex alignItems="center" mb={1}>
            <Text fontSize={16} fontWeight={500}>
              Barclay Bank ••00012
            </Text>
            <Badge
              ml={4}
              bgColor="rgba(255, 102, 51, 0.1)"
              color="#FF6633"
              borderRadius={16}
              fontSize={12}
              fontWeight={800}
              px={4}
              py={0.5}
              mr={4}
              textTransform="none"
            >
              Complete
            </Badge>
          </Flex>
          <Text color="#B8C3D9" fontSize={10} fontWeight={500} mb={4}>
            Sep 22 @ 11:02 PM @ 12:02 CET
          </Text>

          <Link
            href="/transactions"
            color="#2066E6"
            fontSize={13}
            textDecoration="underline"
            mb={4}
          >
            View transactions
          </Link>

          <Textarea
            rows={4}
            border="2px solid #F0F2F7"
            boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
            borderRadius={12}
            placeholder="Add Payment details..."
            mb={4}
            resize="none"
          ></Textarea>

          <FileUpload
            fileTypeLabel="(PDF/JPG/PNG)"
            options={{ multiple: true }}
          />
          {/* <Flex
            alignItems="center"
            borderRadius={16}
            border="2px dashed #CFD6E6"
            mb={4}
          >
            <Box p={8}>
              <MoonIcon />
            </Box>
            <Flex direction="column" p={4}>
              <Text fontSize={14} fontWeight={500} color="#000">
                Supporting Documents
              </Text>
              <Text fontSize={14} fontWeight={500} color="#B8C3D9">
                Drag & Drop or click to upload
              </Text>
              <Text fontSize={10} fontWeight={500} color="#B8C3D9">
                (PDF/JPG/PNG)
              </Text>
            </Flex>
          </Flex>

          <Flex justifyContent="flex-end">
            <Button
              bgColor="rgba(51, 97, 255, 0.1)"
              color="#2066E6"
              borderRadius={16}
              colorScheme="blue"
              fontSize={12}
              py={2}
              px={6}
              // h={8}
            >
              Save
            </Button>
          </Flex> */}
        </Flex>
      </Box>
    </Box>
  );
};
