import { IconButton } from "@chakra-ui/react";

export const OutlinedIconButton = ({ ariaLabel, icon, ...rest }: any) => {
  return (
    <IconButton
      aria-label={ariaLabel}
      backgroundColor="white"
      color="grayBlue.85"
      border="2px"
      borderColor="grayBlue.97"
      borderRadius={16}
      fontSize={20}
      h="32px"
      w="32px"
      minW="32px"
      icon={icon}
      _hover={{
        bgColor: "primary",
        borderColor: "primary",
        color: "white",
        boxShadow: "0px 2px 5px rgba(38, 51, 77, 0.03)",
      }}
      {...rest}
    ></IconButton>
  );
};
