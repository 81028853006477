import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Th as ChakraTh,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useCounter } from "@react-hookz/web";
import { useNavigate } from "react-router-dom";

import { StatementRecord } from "../../api/accountApi";
import { HistoryIcon } from "../../assets/icons/History";
import { InfoIcon } from "../../assets/icons/Info";
import { OutlinedIconButton } from "../../components/Button";

const Th = (props) => {
  return <ChakraTh textTransform="none" {...props} />;
};

const Row = ({
  data,
  isSelected,
  onClick,
}: {
  data: StatementRecord;
  isSelected: boolean;
  onClick: any;
}) => {
  const value = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(data.amount);

  return (
    <Tr
      cursor="pointer"
      color="grayBlue.55"
      onClick={onClick}
      boxShadow={
        isSelected ? "0px 2px 15px rgba(51, 97, 255, 0.15)" : undefined
      }
      bgColor={isSelected ? "#fff" : undefined}
      _hover={{
        bgColor: "#fff",
        boxShadow: "0px 2px 15px rgba(51, 97, 255, 0.15)",
      }}
    >
      <Td w={100}>
        <Flex>
          <InfoIcon color="grayBlue.80" fontSize={24} mr={2} />
          <HistoryIcon color="grayBlue.80" fontSize={24} />
        </Flex>
      </Td>
      <Td>{data.transaction_reference}</Td>
      <Td>{data.date}</Td>
      <Td>
        <Badge
          variant="solid"
          colorScheme="green"
          px={3}
          py={0.5}
          fontSize={12}
        >
          Sent
        </Badge>
      </Td>
      <Td>{data.details}</Td>
      <Td>{data.sender_name}</Td>
      <Td isNumeric color="#1C1C1C" fontWeight={500}>
        {value}
      </Td>
    </Tr>
  );
};

type Props = {
  data: StatementRecord[];
  selectedTxn?: StatementRecord;
  onRowClick?: (r?: StatementRecord) => void;
};

export const TransactionsTable = ({ data, selectedTxn, onRowClick }: Props) => {
  // const ref = useRef<HTMLTableElement>(null);
  // useOutsideClick({
  //   ref: ref,
  //   handler: () => onRowClick(undefined),
  // });
  const navigate = useNavigate();

  const handleClick = (r?: StatementRecord) => {
    if (onRowClick) {
      return onRowClick(r);
    }

    if (!r) return;

    navigate(`/transactions/${r.transaction_reference}`);
  };

  return (
    <TableContainer>
      <Flex w="100%" mb={8}>
        <Select
          w={300}
          borderRightRadius={0}
          borderRight="2px solid transparent"
          bgColor="#fff"
          _focus={{ boxShadow: "none" }}
          defaultValue="option1"
        >
          <option value="option1">All transactions</option>
          <option value="option2">Paid</option>
          <option value="option3">Received</option>
        </Select>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            placeholder="Search"
            borderLeftRadius={0}
            bgColor="#fff"
            _focus={{ boxShadow: "none" }}
          />
        </InputGroup>
      </Flex>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Actions</Th>
            <Th>ID</Th>
            <Th>Date</Th>
            <Th>Status</Th>
            <Th>Details</Th>
            <Th>Beneficiary/Payer</Th>
            <Th isNumeric>Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((r) => (
            <Row
              key={r.transaction_reference}
              data={r}
              isSelected={
                r.transaction_reference === selectedTxn?.transaction_reference
              }
              onClick={() => handleClick(r)}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
