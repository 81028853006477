import { Icon } from "@chakra-ui/react";

export const EditIcon = (props: any) => (
  <Icon viewBox="0 0 12 12" {...props}>
    <path
      d="M7.02939 4.51L7.48939 4.97L2.95939 9.5H2.49939V9.04L7.02939 4.51ZM8.82939 1.5C8.70439 1.5 8.57439 1.55 8.47939 1.645L7.56439 2.56L9.43939 4.435L10.3544 3.52C10.5494 3.325 10.5494 3.01 10.3544 2.815L9.18439 1.645C9.08439 1.545 8.95939 1.5 8.82939 1.5ZM7.02939 3.095L1.49939 8.625V10.5H3.37439L8.90439 4.97L7.02939 3.095Z"
      fill="currentColor"
    />
  </Icon>
);
