import { Icon } from "@chakra-ui/react";

export const HistoryIcon = (props: any) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M14.6667 10.6667V17.3333L20.3333 20.6933L21.36 18.9867L16.6667 16.2V10.6667H14.6667ZM28 13.3333V4L24.48 7.52C22.32 5.34667 19.32 4 16 4C9.37333 4 4 9.37333 4 16C4 22.6267 9.37333 28 16 28C22.6267 28 28 22.6267 28 16H25.3333C25.3333 21.1467 21.1467 25.3333 16 25.3333C10.8533 25.3333 6.66667 21.1467 6.66667 16C6.66667 10.8533 10.8533 6.66667 16 6.66667C18.5733 6.66667 20.9067 7.72 22.6 9.4L18.6667 13.3333H28Z"
      fill="currentColor"
    />
  </Icon>
);
