import { Heading } from "@chakra-ui/react";

export const FormTitle = ({ children, ...rest }: any) => {
  return (
    <Heading
      as="h1"
      size="2xl"
      noOfLines={1}
      fontSize={36}
      mb={4}
      fontWeight={500}
      {...rest}
    >
      {children}
    </Heading>
  );
};
