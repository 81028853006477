import { Box, Button, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { NavLink } from "react-router-dom";

import { MessagesTable } from "./MessagesTable";
import { ViewMessage } from "./ViewMessage";

export const MessagesPage = () => {
  const [messageId, setMessageId] = useState<null | number>(null);
  const handleMessageClick = (id: number) => {
    setMessageId(id);
  };

  return (
    <Box p={8}>
      <Flex justifyContent="flex-end" mb={8}>
        <Button variant="primary" as={NavLink} to="/messages/new">
          New message
        </Button>
      </Flex>

      <MessagesTable onMessageClick={handleMessageClick} />

      <Box mb={8} />

      {messageId && (
        <ViewMessage id={messageId} onClose={() => setMessageId(null)} />
      )}
    </Box>
  );
};
