import { ChevronDownIcon, RepeatIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { AuthContext } from "auth/AuthProvider";
import { useContext } from "react";
import {
  AiOutlineKey,
  AiOutlineLogout,
  AiOutlineUser,
  AiOutlineUserAdd,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";

const OpenAccountMenu = () => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon fontSize={20} />}
        // bgColor="rgba(51, 97, 255, 0.1)"
        color="primary"
        borderRadius={22}
        borderColor="primary"
        fontSize={14}
        pr={5}
        pl={6}
      >
        Open account
      </MenuButton>
      <MenuList
        borderRadius={16}
        fontSize={14}
        fontWeight={700}
        color="#7D8FB3"
        boxShadow="0px 30px 60px rgba(0, 0, 0, 0.2)"
        zIndex={10}
      >
        <MenuItem
          icon={
            <Box fontSize={24}>
              <AiOutlineUserAdd />
            </Box>
          }
        >
          Add account
        </MenuItem>
        <MenuDivider />
        <MenuItem
          icon={
            <Box fontSize={24}>
              <AiOutlineUsergroupAdd />
            </Box>
          }
        >
          Invite a Friend
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const UserMenu = () => {
  const { onLogout } = useContext(AuthContext);

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon fontSize={20} />}
        background="none"
        color="#5C7099"
        borderRadius={22}
        fontSize={14}
        pr={5}
        pl={6}
        boxShadow="none"
      >
        Alexander Legoshin
      </MenuButton>
      <MenuList
        borderRadius={16}
        fontSize={14}
        fontWeight={700}
        color="#7D8FB3"
        boxShadow="0px 30px 60px rgba(0, 0, 0, 0.2)"
        zIndex={10}
      >
        <MenuItem
          icon={
            <Box fontSize={24}>
              <AiOutlineUser />
            </Box>
          }
        >
          Profile
        </MenuItem>
        <MenuDivider />
        <MenuItem
          icon={
            <Box fontSize={24}>
              <AiOutlineKey />
            </Box>
          }
        >
          Referrals
        </MenuItem>
        <MenuDivider />
        <MenuItem
          icon={
            <Text fontSize={24} lineHeight="24px">
              <RepeatIcon />
            </Text>
          }
        >
          Switch account
        </MenuItem>
        <MenuDivider />
        <MenuItem
          icon={
            <Box fontSize={24}>
              <AiOutlineLogout />
            </Box>
          }
          onClick={onLogout}
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export const RightMenu = () => {
  return (
    <Flex gap={4}>
      <UserMenu />
      <OpenAccountMenu />
    </Flex>
  );
};
