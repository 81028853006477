import { Box, Flex, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { accountApi } from "../../api/accountApi";
import { AccountCard, AccountCardData } from "./AccountCard";

export type Account = {
  id: string;
  iban: string;
  bic: string;
  name: string;
  type: string;
  status: string;
};

export const Accounts = () => {
  const { data: accounts = [], isLoading } = useQuery<Account[]>(
    ["accounts"],
    async () => {
      return accountApi.list();
    }
  );

  console.log({ accounts });

  return (
    <Box p={6} pr={0} borderBottomWidth="2px" borderBottomColor="grayBlue97">
      <Text as="h1" fontSize={36} fontWeight={700}>
        Accounts
      </Text>
      <Flex
        py={6}
        pl={2}
        gap={6}
        overflowX="scroll"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {accounts.map((acc) => (
          <AccountCard key={acc.id} accountId={acc.id} type={acc.type} />
        ))}
      </Flex>
    </Box>
  );
};
