import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, IconButton, Text } from "@chakra-ui/react";

import pattern from "./pattern.png";

export const EmptyCard = () => {
  return (
    <Flex
      minW={311}
      h={200}
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
      borderRadius={18}
      border="1px solid #E6EAF2"
      color="#fff"
    >
      <Button ml={2} variant="primary">
        <Text mr={2}>Get card</Text>
        <AddIcon />
      </Button>
    </Flex>
  );
};
