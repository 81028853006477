import { Icon } from "@chakra-ui/react";

export const InboxIcon = (props: any) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      opacity="0.3"
      d="M12.01 18C10.53 18 9.26 17.19 8.56 16H5V19H19V16H15.45C14.76 17.19 13.48 18 12.01 18Z"
      fill="currentColor"
    />
    <path
      d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V16H8.56C9.25 17.19 10.53 18 12.01 18C13.49 18 14.76 17.19 15.46 16H19V19ZM19 14H14C14 15.1 13.1 16 12 16C10.9 16 10 15.1 10 14H5V5H19V14Z"
      fill="currentColor"
    />
  </Icon>
);
