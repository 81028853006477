import { AddIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { useCounter } from "@react-hookz/web";
import { useQuery } from "@tanstack/react-query";
import { StatementRecord, accountApi } from "api/accountApi";
import { ConvertIcon } from "assets/icons/Convert";
import { CopyIcon } from "assets/icons/Copy";
import { EditIcon } from "assets/icons/Edit";
import { MoreIcon } from "assets/icons/More";
import { PaymentIcon } from "assets/icons/Payment";
import { ShareIcon } from "assets/icons/Share";
import { UserIcon } from "assets/icons/User";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { formatBalance } from "utils/formatters";

import { OutlinedIconButton } from "components/Button";
import { TransactionsTable } from "components/TransactionsTable";

import { Header } from "./Header";

const MoreMenuItem = () => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="secondary"
        size="sm"
        mr={4}
        color="grayBlue.50"
        bgColor="grayBlue.97"
      >
        More <MoreIcon ml={2} fontSize={18} />
      </MenuButton>
      <MenuList
        borderRadius={16}
        fontSize={14}
        fontWeight={700}
        color="#7D8FB3"
        boxShadow="0px 30px 60px rgba(0, 0, 0, 0.2)"
        zIndex={10}
      >
        <MenuItem>Referrals</MenuItem>
        <MenuDivider />

        <MenuItem>Payments</MenuItem>
        <MenuDivider />
        <MenuItem>Switch account</MenuItem>
      </MenuList>
    </Menu>
  );
};

const LIMIT = 4;

export const AccountPage = () => {
  const { accountId } = useParams();
  const [selectedTxn, setSelectedTxn] = useState<StatementRecord>();
  const [page, counterActions] = useCounter(0, 1000, 0);

  const { data: statement, isLoading } = useQuery(
    ["transactions", accountId],
    () => {
      if (!accountId) return null;
      return accountApi.getStatement(accountId!);
    }
  );

  const transactions = statement?.records.slice(
    page * LIMIT,
    (page + 1) * LIMIT
  );

  const { onCopy, value, setValue, hasCopied } = useClipboard(
    statement?.balance.iban || ""
  );

  if (!statement) {
    return null;
  }

  // const handleRowClick = (r?: StatementRecord) => setSelectedTxn(r);

  if (!transactions) return null;

  return (
    <Box px={8}>
      <Header />

      <Box mb={4}>
        <Flex justifyContent="space-between">
          <Box>
            <Badge mb={2}>{statement.balance.currency}</Badge>
            <Text color="#969696" fontSize={16} fontWeight={500}>
              The euro (symbol: €; code: EUR)
            </Text>
          </Box>
          <OutlinedIconButton
            aria-label="Share"
            icon={<ShareIcon />}
          ></OutlinedIconButton>
        </Flex>
        <Text fontSize={48} color="primary">
          {formatBalance(
            statement.balance.closing.balance,
            statement.balance.currency
          )}
        </Text>
        <Text color="grayBlue.70" fontSize={16} fontWeight={500} mb={4}>
          {formatBalance(
            statement.balance.opening.balance,
            statement.balance.currency
          )}
        </Text>

        <Flex mb={4}>
          <Button variant="primary" size="sm" mr={4}>
            Pay <PaymentIcon ml={2} fontSize={18} />
          </Button>
          <Button variant="primary" size="sm" mr={4}>
            Add money <AddIcon ml={2} fontSize={12} />
          </Button>
          <Button variant="primary" size="sm" mr={4}>
            Convert <ConvertIcon ml={2} fontSize={18} />
          </Button>
          <MoreMenuItem />
        </Flex>

        <Flex alignItems="center">
          <UserIcon color="grey.70" />
          <Text
            textTransform="capitalize"
            mx={1.5}
            fontSize={14}
            fontWeight={500}
            color="grey.70"
          >
            Personal
          </Text>
          <EditIcon color="grey.70" mr={4} />

          <Text
            textTransform="capitalize"
            mr={2}
            fontSize={14}
            fontWeight={700}
            color="grey.70"
          >
            {statement.balance.iban}
          </Text>
          <CopyIcon
            color="grey.70"
            fontSize={13}
            onClick={onCopy}
            cursor="pointer"
          />
        </Flex>
      </Box>

      <Divider mb={6} />

      <TransactionsTable
        data={transactions}
        selectedTxn={selectedTxn}
        // onRowClick={handleRowClick}
      />

      <Flex justifyContent="flex-end" alignItems="center" mt={6}>
        {/* <Text mr={2} fontSize={16} fontWeight={500} color="grayBlue.80">
          1-1 of 1
        </Text> */}
        <Box>
          <OutlinedIconButton
            ariaLabel="Previous"
            icon={<ChevronLeftIcon />}
            mr={2}
            onClick={() => counterActions.dec()}
            disabled={page < 1}
          />
          <OutlinedIconButton
            ariaLabel="Next"
            icon={<ChevronRightIcon />}
            onClick={() => counterActions.inc()}
            disabled={statement?.records.length <= (page + 1) * LIMIT}
          />
        </Box>
      </Flex>
    </Box>
  );
};
