import { Icon } from "@chakra-ui/react";

export const ShareIcon = (props: any) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.3333 4.16671L12.15 5.35004L10.825 4.02504V13.3334H9.17498V4.02504L7.84998 5.35004L6.66665 4.16671L9.99998 0.833374L13.3333 4.16671ZM16.6666 8.33337V17.5C16.6666 18.4167 15.9166 19.1667 15 19.1667H4.99998C4.07498 19.1667 3.33331 18.4167 3.33331 17.5V8.33337C3.33331 7.40837 4.07498 6.66671 4.99998 6.66671H7.49998V8.33337H4.99998V17.5H15V8.33337H12.5V6.66671H15C15.9166 6.66671 16.6666 7.40837 16.6666 8.33337Z"
      fill="currentColor"
    />
  </Icon>
);
