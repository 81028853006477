import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useToggle } from "@react-hookz/web";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";

import { api } from "../../api/api";
import { EyeIcon } from "../../assets/icons/Eye";
// import { EditIcon } from "../../assets/icons/Edit";
import { OutlinedIconButton } from "../../components/Button";
import { Message } from "./types";

const StyledTh = ({ children }: any) => {
  return (
    <Th textTransform="none" color="grayBlue.80" padding={6}>
      {children}
    </Th>
  );
};

const MessageTd = ({
  message,
  onMessageClick,
}: {
  message: Message;
  onMessageClick: any;
}) => {
  const [checked, toggle] = useToggle(false);

  return (
    <Tr>
      <Td color="grayBlue.50" fontWeight={700} padding={6} width={50}>
        <Checkbox
          size="lg"
          colorScheme="blue"
          isChecked={checked}
          onChange={() => toggle()}
        ></Checkbox>
      </Td>
      <Td color="grayBlue.50" fontWeight={700} padding={6}>
        {message.author}
      </Td>
      <Td
        color="grayBlue.70"
        fontSize={12}
        maxW={140}
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {message.subject}
      </Td>
      <Td color="grayBlue.70" fontSize={12}>
        {format(message.date, "dd.MM.yyyy")}
      </Td>
      <Td w={120}>
        <Flex>
          {/* <IconButton
            aria-label="Use template"
            boxShadow="none"
            borderRadius={4}
            color="grayBlue.85"
            fontSize={16}
            icon={<EditIcon />}
          /> */}
          <IconButton
            aria-label="View"
            boxShadow="none"
            borderRadius={4}
            color="grayBlue.85"
            fontSize={20}
            icon={<EyeIcon />}
            onClick={() => onMessageClick(message.id)}
          />
          <IconButton
            aria-label="Delete"
            boxShadow="none"
            borderRadius={4}
            color="grayBlue.85"
            fontSize={16}
            icon={<DeleteIcon />}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export const MessagesTable = ({ onMessageClick }: any) => {
  const { data: messages = [], isLoading } = useQuery<Message[]>(
    ["messages", "limit", 5, "page", 1],
    async () => {
      const { data } = await api.get(
        `/api/messages?_page=1&_limit=5&_sort=date&_order=desc`
      );
      return data;
    },
    {
      retry: false,
    }
  );

  return (
    <Box>
      <TableContainer
        bgColor="#fff"
        borderRadius={16}
        boxShadow="0px 2px 10px rgba(38, 51, 77, 0.05)"
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <StyledTh></StyledTh>
              <StyledTh>Author</StyledTh>
              <StyledTh>Subject</StyledTh>
              <StyledTh>Date</StyledTh>
              <StyledTh>Actions</StyledTh>
            </Tr>
          </Thead>
          {messages && (
            <Tbody>
              {messages.map((m) => {
                return (
                  <MessageTd
                    key={m.id}
                    message={m}
                    onMessageClick={onMessageClick}
                  />
                );
              })}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <Flex justifyContent="flex-end" alignItems="center" mt={6}>
        <Text mr={2} fontSize={16} fontWeight={500} color="grayBlue.80">
          1-1 of 1
        </Text>
        <Box>
          <OutlinedIconButton
            ariaLabel="Previous"
            icon={<ChevronLeftIcon />}
            mr={2}
          />
          <OutlinedIconButton ariaLabel="Next" icon={<ChevronRightIcon />} />
        </Box>
      </Flex>
    </Box>
  );
};
