import {
  Box,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import { faker } from "@faker-js/faker";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-luxon";
import { DateTime } from "luxon";
import { useTheme } from "providers/theme/ThemeProvider";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  Filler,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

function newDateString(days) {
  return DateTime.now().plus({ days }).toISO();
}

const getOptions = () => {
  return {
    responsive: true,
    elements: {
      point: {
        backgroundColor: "red",
      },
    },
    plugins: {
      filler: {
        propagate: false,
      },
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
        // text: "Chart.js Line Chart",
      },
      tooltip: {
        backgroundColor: "0C0B0B",
        color: "white",
        padding: 10,
      },
    },

    scales: {
      y: {
        min: 0,
        max: 40000,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x: {
        type: "time" as any,
        min: DateTime.now().minus({ month: 5 }).toISO(),
        time: {
          // Luxon format string
          tooltipFormat: "DD T",
          displayFormats: {
            month: "MMM",
          },
          unit: "month",
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
  };
};

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"]
  .map((label) => [label, "", ""])
  .flat();

const generateData = (days: number) => {
  const mock: any = [];
  while (days > 0) {
    mock.push({
      x: newDateString(-days),
      y: faker.datatype.number({ min: 20000, max: 30000 }),
    });
    days -= 5;
  }

  return mock;
};

const mockData = generateData(180);

export const Chart = () => {
  const theme = useTheme();
  const options = getOptions();
  options.scales.x.min = mockData[0].x;
  console.log({ theme });

  const data = {
    // labels,
    datasets: [
      {
        // label: "Spent this month",
        // data: labels.map(() => faker.datatype.number({ min: 12000, max: 30000 })),
        data: mockData,
        borderColor: theme.colors.primary,
        backgroundColor: "#E9F0FC",
        pointStyle: "circle",
        pointRadius: 0,
        hitRadius: 10,
        pointHoverRadius: 5,
        fill: "start",
      },
    ],
  };

  return (
    <Box
      p={4}
      borderRadius={16}
      bgColor="#fff"
      // height={450}
      position="relative"
      // width={450}
      // flex={1}
    >
      <Box mb={4}>
        <Stat>
          <StatLabel>Spent this month</StatLabel>
          <StatNumber>4,670</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            23.36%
          </StatHelpText>
        </Stat>

        {/* <Text color="grayBlue.80" fontSize={14} fontWeight={500}>
          Spend today
        </Text>
        <Text color="grayBlue.60" fontSize={30} fontWeight={500}>
          4 556
        </Text> */}
      </Box>
      <Line options={options} data={data} />
    </Box>
  );
};
