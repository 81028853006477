import axios from "axios";

import config from "../config";

export type Statement = {
  issue_date: string;
  balance: {
    iban: string;
    currency: string;
    opening: {
      date: string;
      balance: number;
    };
    closing: {
      date: string;
      balance: number;
    };
  };
  records: StatementRecord[];
};

export type StatementRecord = {
  document_reference: string;
  external_id: string;
  transaction_reference: string;
  date: string;
  type: string;
  amount: number;
  currency: string;
  details: string;
  recipient_bic: string;
  recipient_account: string;
  recipient_name: string;
  sender_bic: string;
  sender_account: string;
  sender_name: string;
};

export class PaymentApi {
  private http;

  constructor(baseUrl: string) {
    this.http = axios.create({
      baseURL: baseUrl,
    });
  }

  async createInternalPayment(data) {
    return this.http.post("/api/payments/internal", data);
  }
}


export const paymentApi = new PaymentApi(config.apiBaseUrl);
