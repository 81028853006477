import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { useCallback, useRef, useState } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

import { CloudUploadIcon } from "../../../assets/icons/CloudUpload";

type Props = {
  name?: string;
  buttonLabel?: string;
  fileTypeLabel?: string;
  options?: DropzoneOptions;
  boxProps?: any;
};

export const FileUpload = ({
  name = "paymentsfile",
  fileTypeLabel,
  buttonLabel = "Save",
  options = {},
  boxProps = {},
}: Props) => {
  const { setFieldValue } = useFormikContext();

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files

      if (acceptedFiles?.[0]) {
        setFieldValue(name, acceptedFiles[0]);
      }
    },
    [name, setFieldValue]
  );
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      onDrop,
      ...options,
    });

  return (
    <Flex direction="column" {...boxProps}>
      <Flex
        alignItems="center"
        borderRadius={16}
        // border="2px dashed #CFD6E6"
        border={isDragActive ? "2px dashed #2066E6" : "2px dashed #CFD6E6"}
        backgroundColor={isDragActive ? "#f2f5ff" : "#fff"}
        mb={4}
        p={2}
        cursor="pointer"
        {...getRootProps()}
      >
        <input name={name} {...getInputProps()} />
        <Box p={4}>
          <CloudUploadIcon w="24px" h="24px" color="grayBlue.85" />
        </Box>
        <Flex direction="column" p={4}>
          <Text fontSize={14} fontWeight={700} color="#000">
            {isDragActive
              ? "Drop the files here"
              : acceptedFiles[0]?.name ?? "Choose file"}
          </Text>
          <Text fontSize={14} fontWeight={500} color="#B8C3D9">
            Drag & Drop or click to upload
          </Text>
          {fileTypeLabel && (
            <Text fontSize={10} fontWeight={500} color="#B8C3D9">
              {fileTypeLabel}
            </Text>
          )}
        </Flex>
      </Flex>

      {buttonLabel && (
        <Flex justifyContent="flex-end">
          <Button variant="secondary">{buttonLabel}</Button>
        </Flex>
      )}
    </Flex>
  );
};
