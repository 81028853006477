import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
} from "@chakra-ui/react";
import {
  List,
  ListIcon,
  ListItem,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { useMemo } from "react";

import { accountApi } from "../../../api/accountApi";
import { ReactComponent as DashIcon } from "../../../assets/icons/list-line-bullet.svg";
import { Account } from "../../Dashboard/Accounts";
import { AccountSelect } from "./AccountSelect";
import { PaymentDetails } from "./PaymentDetails";

const initialData = {
  debitaccountid: "",
  amount: "0",
  paymentsfile: undefined,
};

export type InternationalPaymentsFormData = typeof initialData;

const DashListItem = ({ children }: any) => {
  return (
    <ListItem mb={4}>
      <ListIcon color="primary" as={DashIcon}></ListIcon>
      {children}
    </ListItem>
  );
};

export const BulkPaymentsForm = () => {
  const { data: accounts, isLoading } = useQuery<Account[]>(
    ["accounts"],
    async () => {
      return accountApi.list();
      // const res = await fetch("http://localhost:3004/api/accounts");
      // return res.json();
    }
  );

  const initialValues = useMemo(() => {
    if (!accounts?.length) {
      return initialData;
    }

    return {
      ...initialData,
      debitaccountid: accounts[0].id,
    };
  }, [accounts]);

  if (!accounts?.length) {
    return <Text>Loading</Text>;
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        console.log({ values });
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({ isSubmitting, handleSubmit, handleChange, values }) => (
        <Form onSubmit={handleSubmit}>
          <Flex>
            <Box width="60%" mr={8}>
              <AccountSelect
                accounts={accounts}
                handleChange={handleChange}
                accountFieldName="debitaccountid"
                amountFieldName="amount"
              />

              <Box mb={12} />
            </Box>
            <Box width="40%">
              <PaymentDetails />
            </Box>
          </Flex>

          <Divider my={8} />

          <Flex mb={8}>
            <Box width="50%" mr={8}>
              <List color="grayBlue.50" fontWeight={700} fontSize={16}>
                <ListItem mb={4}>
                  Payments can be loaded from file with comma separated values
                  (csv) or Excel Workbook (xlsx).
                </ListItem>
                <ListItem mb={4}>
                  First (row #1) line of the file should contain unique names of
                  fields in any order (headers). Headers must correspond to
                  field values in lines below.
                </ListItem>
                <ListItem>
                  Unnecessary fields can be empty, i.e. just nothing between
                  commas (,,)in .csv file or empty cell in .xlsx
                </ListItem>
              </List>
            </Box>
            <Box width="50%">
              <List color="grayBlue.50" fontWeight={700} fontSize={16}>
                <DashListItem>
                  Mandatory fields for SEPA payment:
                  iban,amount,beneficiary,info (currency always is EUR, so can
                  be omitted)
                </DashListItem>
                <DashListItem>
                  Mandatory fields for internal payment:
                  iban,amount,currency,beneficiary,info
                </DashListItem>
                <DashListItem>
                  Mandatory fields for international (ISWIFT) payment: iban,
                  amount, currency, beneficiary, info, bic, bank
                </DashListItem>
              </List>
            </Box>
          </Flex>

          <Divider my={8} />

          <Flex mb={8}>
            <Box width="50%" mr={8}>
              <List color="grayBlue.50" fontWeight={700} fontSize={16}>
                <DashListItem>
                  Charges — SHA by default for SEPA, OUR for SWIFT(possible
                  values: BEN, SHA, OUR)
                </DashListItem>
                <DashListItem>
                  Priority — standard by default(possible values: standard,
                  urgent)
                </DashListItem>
                <DashListItem>Address — beneficiary address</DashListItem>
                <DashListItem>
                  Country — beneficiary country (ISO 3166-1 alpha-2 code)
                </DashListItem>
                <DashListItem>Email — beneficiary e-mail address</DashListItem>
                <DashListItem>
                  Regnumber — beneficiary registration number
                </DashListItem>
                <DashListItem>
                  Text values should be enclosed in quotes (" ")
                </DashListItem>
              </List>
            </Box>
            <Box width="50%">
              <List color="grayBlue.50" fontWeight={700} fontSize={16}>
                <DashListItem>
                  Bankaddres — beneficiary bank address
                </DashListItem>
                <DashListItem>
                  Bankcountry — beneficiary bank country(ISO 3166-1 alpha-2
                  code)
                </DashListItem>
                <DashListItem>Intbic — intermediary bank BIC</DashListItem>

                <DashListItem>Intbank — intermediary bank name</DashListItem>
                <DashListItem>
                  Intbankaddress — intermediary bank adddress
                </DashListItem>
                <DashListItem>
                  Intbankcountry — intermediary bank country(ISO 3166-1 alpha-2
                  code)
                </DashListItem>
                <DashListItem>
                  Intaccount — intermediary bank account
                </DashListItem>
              </List>
            </Box>
          </Flex>

          <Divider my={4} />

          <Button
            mt={4}
            variant="primary"
            type="submit"
            disabled={isSubmitting}
            w="100%"
          >
            Create Bulk Payment
          </Button>
        </Form>
      )}
    </Formik>
  );
};
