import { Box, Flex, SimpleGrid } from "@chakra-ui/react";

import { Accounts } from "./Accounts";
import { MessagesCard } from "./MessagesCard";
import { PromotionSection } from "./PromotionSection";
import { Transactions } from "./Transactions";
import { UserCardsCard } from "./UserCards/UserCardsCard";

export const Dashboard = () => {
  return (
    <Flex direction="column" pb={8}>
      <Accounts />

      <Transactions />

      <SimpleGrid columns={{ sm: 1, xl: 2 }} gap={8} p={4}>
        <MessagesCard />
        <UserCardsCard />
      </SimpleGrid>

      <Box borderBottomWidth="2px" borderBottomColor="grayBlue97" my={4}></Box>

      <PromotionSection />
    </Flex>
  );
};
