import { Icon } from "@chakra-ui/react";

export const FreezeIcon = (props: any) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      d="M14.25 7.375H11.6438L13.6688 5.35L12.7875 4.4625L9.875 7.375H8.625V6.125L11.5375 3.2125L10.65 2.33125L8.625 4.35625V1.75H7.375V4.35625L5.35 2.33125L4.4625 3.2125L7.375 6.125V7.375H6.125L3.2125 4.4625L2.33125 5.35L4.35625 7.375H1.75V8.625H4.35625L2.33125 10.65L3.2125 11.5375L6.125 8.625H7.375V9.875L4.4625 12.7875L5.35 13.6688L7.375 11.6438V14.25H8.625V11.6438L10.65 13.6688L11.5375 12.7875L8.625 9.875V8.625H9.875L12.7875 11.5375L13.6688 10.65L11.6438 8.625H14.25V7.375Z"
      fill="currentColor"
    />
  </Icon>
);
