import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";
import { AuthContext } from "auth/AuthProvider";
import React, { useContext } from "react";
import {
  MdDashboard,
  MdOutlineHistory,
  MdOutlineInbox,
  MdOutlineSettings,
  MdPayment,
  MdPayments,
  MdTripOrigin,
} from "react-icons/md";
import { RxGear } from "react-icons/rx";
import { NavLink, To } from "react-router-dom";

import { HistoryIcon } from "../../assets/icons/History";
import { InboxIcon } from "../../assets/icons/Inbox";
import { UserIcon } from "../../assets/icons/User";
import { ReactComponent as CardIcon } from "../../assets/icons/card.svg";
import { ReactComponent as PaymentIcon } from "../../assets/icons/payments.svg";
import { UserInfo } from "./UserInfo";

const MenuLink = ({
  children,
  to,
  end = true,
  ...rest
}: React.PropsWithChildren<{ to: To; end?: boolean; [key: string]: any }>) => {
  return (
    <Link
      as={NavLink}
      end
      to={to}
      py={3}
      display="flex"
      color="grayBlue.60"
      alignItems="center"
      borderLeft="4px solid transparent"
      _activeLink={{
        borderLeft: "4px",
        borderColor: "primary",
        color: "primary",
      }}
      {...rest}
    >
      {children}
    </Link>
  );
};

export const Sidebar: React.FC = () => {
  const { token, userValidated } = useContext(AuthContext);

  return (
    <Box h="100%" w="100%" borderRight="2px solid #f0f2f7">
      <UserInfo />
      {/* <Box mb={4} /> */}

      {!userValidated && (
        <Accordion allowMultiple allowToggle>
          <MenuLink to="/">
            <Text ml={8} fontSize={20}>
              <UserIcon />
            </Text>
            <Text ml={7} fontSize={14} fontWeight={700}>
              Application
            </Text>
          </MenuLink>
          <MenuLink to="/messages" end={false}>
            <Text mx={8} fontSize={20}>
              <InboxIcon />
            </Text>
            <Text fontSize={14} fontWeight={700}>
              Messages
            </Text>
          </MenuLink>
          <MenuLink to="/settings" end={false}>
            <Text mx={8} fontSize={22}>
              <RxGear />
            </Text>
            <Text fontSize={14} fontWeight={700}>
              Settings
            </Text>
          </MenuLink>
        </Accordion>
      )}
      {userValidated && (
        <Accordion allowMultiple allowToggle>
          <MenuLink to="/">
            <Text mx={8} fontSize={20}>
              <MdDashboard />
            </Text>
            <Text fontSize={14} fontWeight={700}>
              Dashboard
            </Text>
          </MenuLink>
          <MenuLink to="/cards">
            <Text mx={8} fontSize={20}>
              <MdPayment />
            </Text>
            <Text fontSize={14} fontWeight={700}>
              Cards
            </Text>
          </MenuLink>
          <MenuLink to="/payments">
            <Text mx={8} fontSize={20}>
              <MdPayments />
            </Text>
            <Text fontSize={14} fontWeight={700}>
              Payments
            </Text>
          </MenuLink>
          <MenuLink to="/messages" end={false}>
            <Text mx={8} fontSize={20}>
              <MdOutlineInbox />
            </Text>
            <Text fontSize={14} fontWeight={700}>
              Messages
            </Text>
          </MenuLink>
          <MenuLink to="/transactions" end={false}>
            <Text mx={8} fontSize={20}>
              <MdOutlineHistory />
            </Text>
            <Text fontSize={14} fontWeight={700}>
              Operation history
            </Text>
          </MenuLink>

          <AccordionItem border="none">
            <AccordionButton
              py={3}
              px={1}
              _hover={{
                background: "none",
              }}
            >
              <Flex w="100%" pr={4} color="grayBlue.60">
                <Text mx={8} fontSize={20}>
                  <MdTripOrigin />
                </Text>
                <Text fontSize={14} fontWeight={700} flex={1} align="left">
                  White Label
                </Text>
                <AccordionIcon />
              </Flex>
            </AccordionButton>
            <AccordionPanel p={0}>
              <Box>
                <MenuLink to="/white-label/setup" end={false} pl={16}>
                  <Text fontSize={14} fontWeight={700}>
                    Setup
                  </Text>
                </MenuLink>
                <MenuLink to="/white-label/color" pl={16}>
                  <Text fontSize={14} fontWeight={700}>
                    Interface settings
                  </Text>
                </MenuLink>
                <MenuLink to="/white-label/font" pl={16}>
                  <Text fontSize={14} fontWeight={700}>
                    Font settings
                  </Text>
                </MenuLink>
                <MenuLink to="/white-label/logo" pl={16}>
                  <Text fontSize={14} fontWeight={700}>
                    Product settings
                  </Text>
                </MenuLink>
              </Box>
            </AccordionPanel>
          </AccordionItem>

          <MenuLink to="/settings" end={false}>
            <Text mx={8} fontSize={20}>
              <MdOutlineSettings />
            </Text>
            <Text fontSize={14} fontWeight={700}>
              Settings
            </Text>
          </MenuLink>
        </Accordion>
      )}
    </Box>
  );
};
