import { Box, Text } from "@chakra-ui/react";

export const DashboardCard = ({ children, ...rest }: any) => {
  return (
    <Box
      background="white"
      boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
      borderRadius={8}
      p={4}
      {...rest}
    >
      {children}
    </Box>
  );
};
