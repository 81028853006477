import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Badge, Box, Flex, IconButton, Progress, Text } from "@chakra-ui/react";
import { NavLink, generatePath, useParams } from "react-router-dom";

const steps = [
  {
    num: 1,
    tab: "setup",
    title: "Domain setup",
    text: "Persons representing the company (those actually using the accounts with Gemba) must undergo full identification via Gemba’s onboarding portal (including the liveness checks)",
  },
  {
    num: 2,
    tab: "color",
    title: "Design your Colors",
    text: "Color representing the company (those actually using the accounts with Gemba) must undergo full identification via Gemba’s onboarding portal (including the liveness checks)",
  },
  {
    num: 3,
    tab: "font",
    title: "Design your Fonts",
    text: "Font representing the company (those actually using the accounts with Gemba) must undergo full identification via Gemba’s onboarding portal (including the liveness checks)",
  },
  {
    num: 4,
    tab: "logo",
    title: "Design your Logo",
    text: "Logo representing the company (those actually using the accounts with Gemba) must undergo full identification via Gemba’s onboarding portal (including the liveness checks)",
  },
];

export const WizardCard = () => {
  const { tab = "setup" } = useParams();
  const currentStepIndex = steps.findIndex((s) => s.tab === tab);
  const currentStep = steps.find((s) => s.tab === tab);
  const progress = Math.round((100 / steps.length) * (currentStepIndex + 1));

  return (
    <Box
      py={2}
      px={4}
      bgColor="white"
      boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
      borderRadius={8}
      maxW={500}
    >
      <Text color="grayBlue.50" fontSize={18} fontWeight={700} mb={4}>
        {currentStep?.title}
      </Text>
      <Flex mb={4}>
        <Box mr={6}>
          <Badge px={3} fontSize={12} fontWeight={700}>
            {currentStep?.num}
          </Badge>
        </Box>
        <Text flex={1} color="grayBlue.50" fontSize={14} fontWeight={700}>
          {currentStep?.text}
        </Text>
      </Flex>
      <Progress value={progress} h={1} borderRadius={4} />
      <Flex justifyContent="flex-end" alignItems="center" mt={4}>
        <Box>
          <IconButton
            variant="outline"
            aria-label="Previous"
            icon={<ChevronLeftIcon />}
            mr={2}
            isDisabled={currentStepIndex === 0}
            as={NavLink}
            to={generatePath("/white-label/:tab", {
              tab: steps[currentStepIndex - 1]?.tab ?? steps[0].tab,
            })}
          />
          <IconButton
            variant="outline"
            aria-label="Next"
            icon={<ChevronRightIcon />}
            isDisabled={currentStepIndex === steps.length - 1}
            as={NavLink}
            to={generatePath("/white-label/:tab", {
              tab: steps[currentStepIndex + 1]?.tab ?? steps[0].tab,
            })}
          />
        </Box>
      </Flex>
    </Box>
  );
};
