import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Input,
  Text,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { ImCross } from "react-icons/im";
import { MdOutlineRefresh } from "react-icons/md";

import { WizardCard } from "../WizardCard";
import { BgFilePreview } from "./BgFilePreview";
import { FilePreview } from "./FilePreview";
import { ImgUpload } from "./ImgUpload";

export const LogoTab = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [bgFiles, setBgFiles] = useState([]);

  const getFileCallback = (callback) => (acceptedFiles) => {
    const previewFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    callback(previewFiles);
  };

  const onLogoRemove = (file: File) => {
    const newFiles = files.filter((f) => f.name !== file.name);
    setFiles(newFiles);
  };

  const onLogoEdit = (editedFile: File) => {
    const newFiles = files.map((f) =>
      f.name === editedFile.name ? editedFile : f
    );

    // const newFiles = [...files, editedFile];

    setFiles(newFiles);
  };

  return (
    <Box mx={12} my={0}>
      <Heading as="h1" size="2xl" fontSize={36} mb={6} fontWeight={500}>
        Images & Logotypes
      </Heading>

      <Flex>
        <Box width="60%" maxW={800} mr={8}>
          <ImgUpload
            onDrop={getFileCallback(setFiles)}
            accept={{
              "image/*": [],
            }}
          />

          <Grid templateColumns="repeat(auto-fit, minmax(300px, 50%))">
            {files.map((file: any) => (
              <GridItem key={file.name}>
                <FilePreview
                  file={file}
                  onRemove={onLogoRemove}
                  onEdit={onLogoEdit}
                />
              </GridItem>
            ))}
          </Grid>

          <Divider my={8} />

          <Text fontWeight={700} color="grayBlue.50" fontSize={20} mb={4}>
            Background images
          </Text>

          <ImgUpload
            onDrop={getFileCallback(setBgFiles)}
            accept={{
              "image/*": [],
            }}
          />

          <Grid templateColumns="repeat(auto-fit, minmax(300px, 50%))">
            {bgFiles.map((file: any) => (
              <GridItem key={file.name}>
                <BgFilePreview file={file} />
              </GridItem>
            ))}
          </Grid>

          <Divider my={8} />

          <HStack>
            <Box mr={16}>
              <Text color="grayBlue.80" mb={2}>
                Background Size Height & Width in px
              </Text>
              <HStack>
                <Input
                  bgColor="white"
                  w="80px"
                  borderRadius="30px"
                  type="number"
                  boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
                ></Input>
                <Icon as={ImCross} />
                <Input
                  bgColor="white"
                  w="80px"
                  borderRadius="20px"
                  type="number"
                  boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
                ></Input>
              </HStack>
            </Box>
            <Box>
              <Text color="grayBlue.80" mb={2}>
                Margin Top & Left in px
              </Text>
              <HStack>
                <Input
                  bgColor="white"
                  w="80px"
                  borderRadius="20px"
                  type="number"
                  boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
                ></Input>
                <Icon as={ImCross} />
                <Input
                  bgColor="white"
                  w="80px"
                  borderRadius="20px"
                  type="number"
                  boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
                ></Input>
              </HStack>
            </Box>
          </HStack>

          <Divider my={8} />

          <Flex justifyContent="space-between" my={8}>
            <Button variant="outline">
              <Icon as={MdOutlineRefresh} fontSize={20} mr={1} />
              Reset
            </Button>
            <Button variant="primary" w={300}>
              Save
            </Button>
          </Flex>
        </Box>

        <Box w="40%">
          <WizardCard />
        </Box>
      </Flex>
    </Box>
  );
};
