import {
  Box,
  Button,
  Flex,
  IconButton,
  Img,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { formatFileSize } from "utils/formatters";

type Props = {
  file: File & { preview: string };
  onRemove: any;
  onEdit: any;
};

function useDebounceEffect(fn: () => void, waitTime: number, deps?: any) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}

export const FilePreview = ({ file, onRemove, onEdit }: Props) => {
  console.log({ file });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const imgRef = useRef<HTMLImageElement>(null);

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [src, setSrc] = useState<any>();
  const [croppedImage, setCroppedImage] = useState<any>();
  const [aspect, setAspect] = useState<number | undefined>(1 / 1);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  function encodeImageFileAsURL(file) {
    var reader = new FileReader();
    reader.onloadend = function () {
      console.log("RESULT", reader.result);
      setSrc(reader.result);
    };
    reader.readAsDataURL(file);
  }

  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const edited = new File([u8arr], filename, { type: mime });
    const newFile = Object.assign(edited, {
      preview: URL.createObjectURL(edited),
    });

    setCroppedImage(newFile);
  }

  const handleApply = () => {
    onEdit(croppedImage);
    onClose();
  };

  useEffect(() => {
    encodeImageFileAsURL(file);
  }, [file]);

  async function canvasPreview(
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    crop: PixelCrop,
    scale = 1,
    rotate = 0
  ) {
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("No 2d context");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio;
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const TO_RADIANS = Math.PI / 180;
    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 3) Rotate around the origin
    ctx.rotate(rotateRads);
    // 2) Scale the image
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    ctx.restore();

    const reader = new FileReader();

    canvas.toBlob((blob) => {
      if (!blob) return;
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        dataURLtoFile(reader.result, file.name);
        // dataURLtoFile(reader.result, "croppeg.jpg");
      };
    });
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <>
      <Box
        m={2}
        p={4}
        bgColor="white"
        boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
        borderRadius={8}
        minW={300}
      >
        <Flex alignItems="flex-start">
          <Box w="50px" mr={4}>
            <Img
              src={file.preview}
              className="Header-logo"
              alt="logo"
              maxW="50px"
              maxH="50px"
              margin="auto"
            />
          </Box>

          <VStack align="flex-start" flex={1}>
            <Text color="grayBlue.80" fontSize={10} fontWeight={500}>
              {file.name}
            </Text>
            <Flex>
              {/* <Link
            color="primary"
            fontSize={12}
            fontWeight={500}
            mr={4}
          >
            Replace
          </Link> */}
              <Link
                color="primary"
                fontSize={12}
                fontWeight={500}
                onClick={onOpen}
              >
                Edit
              </Link>
            </Flex>
            <Text color="grayBlue.80" fontSize={10} fontWeight={500}>
              {formatFileSize(file.size)}
            </Text>
          </VStack>

          <IconButton
            aria-label="remove"
            m={-2}
            fontSize={20}
            variant="solid"
            color="grayBlue.85"
            size="sm"
            onClick={() => onRemove(file)}
          >
            <MdOutlineDeleteOutline />
          </IconButton>
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <Box w="70%" mr={8}>
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={src}
                    // style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                    // onLoad={onImageLoad}
                  />
                </ReactCrop>
              </Box>

              <Box w={200} h={200}>
                {!!completedCrop && (
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: "1px solid black",
                      objectFit: "contain",
                      // width: completedCrop.width,
                      // height: completedCrop.height,
                      width: "200px",
                      height: "200px",
                    }}
                  />
                )}
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => handleApply()}>
              Apply
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
