import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Img,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CloudUploadIcon } from "assets/icons/CloudUpload";
import logo from "assets/logo.svg";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { MdOutlineDeleteOutline } from "react-icons/md";

import { WizardCard } from "../WizardCard";

function formatFileSize(bytes, decimalPoint = 0) {
  if (bytes == 0) return "0 Bytes";
  var k = 1000,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint)) + " " + sizes[i]
  );
}

export const BgFilePreview = ({ file }: any) => {
  return (
    <Box
      m={2}
      p={4}
      bgColor="white"
      boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
      borderRadius={8}
      minW={300}
      h="100px"
      backgroundImage={file.preview}
      backgroundSize="cover"
    >
      <Flex alignItems="flex-start">
        {/* <Box w="50px" mr={4}>
          <Img
            src={file.preview}
            className="Header-logo"
            alt="logo"
            maxW="50px"
            maxH="50px"
            margin="auto"
          />
        </Box> */}

        <VStack align="flex-start" flex={1}>
          <Text color="white" fontSize={14} fontWeight={700}>
            {file.name}
          </Text>
          <Text color="white" fontSize={12} fontWeight={700}>
            {formatFileSize(file.size)}
          </Text>
        </VStack>

        <IconButton
          aria-label="remove"
          m={-2}
          fontSize={20}
          variant="solid"
          color="grayBlue.85"
          size="sm"
        >
          <MdOutlineDeleteOutline />
        </IconButton>
      </Flex>
    </Box>
  );
};
