import { ChakraProvider } from "@chakra-ui/react";
import { createContext, useContext } from "react";

import defaultTheme from "./theme";

type ThemeContextType = {
  colors: {
    primary: string;
    logoBg: string | null;
  };
  favicon: string;
  logo: string;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

const useTheme = () => {
  const theme = useContext(ThemeContext);
  if (theme === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }

  return theme;
};

const updateFavicon = (iconUrl: string) => {
  let link = document.querySelector("link[rel*='icon']");
  if (!link) {
    link = document.createElement("link");
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  const linkElement = link as HTMLLinkElement; // Type assertion here
  linkElement.type = "image/x-icon";
  linkElement.rel = "shortcut icon";
  linkElement.href = iconUrl;
};

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  // const { data, isLoading, error } = useQuery(["theme"], async () => {
  //   const response = await axios.get(
  //     "http://localhost:8787/api/design-settings?rootUrl=https://payler.com/"
  //   );
  //   // return response;
  //   console.log({ response });

  //   return {
  //     colors: {
  //       primary: "#7b61ff",
  //     },
  //     logo: "https://static.tildacdn.com/tild3532-3237-4437-a463-333234613038/Payler_LOGO.svg",
  //     favicon:
  //       "https://static.tildacdn.com/tild3231-3265-4034-b231-613539616639/favicon.ico",
  //   };
  // });

  const settings = sessionStorage.getItem("design-settings");

  console.log({ settings });

  const defaultSettins = {
    colors: {
      primary: "#2066E6",
      logoBg: null,
    },
    favicon: "",
    logo: "https://my.ge.mba/themes/default/img/logo.svg",
  };

  const customTheme = settings ? JSON.parse(settings) : defaultSettins;

  const theme = {
    ...defaultTheme,
    colors: { ...defaultTheme.colors, ...customTheme?.colors },
  };

  if (customTheme?.favicon) {
    updateFavicon(customTheme.favicon);
  }

  return (
    <ThemeContext.Provider value={customTheme}>
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext, useTheme };
