import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";

import { accountApi } from "../../../api/accountApi";
import { OutlinedButton, OutlinedIconButton } from "../../../components/Button";
import { DashboardCard } from "../../../components/Card";
import { Card } from "./Card";
import { EmptyCard } from "./EmptyCard";

type Card = {
  id: number;
  number: string;
  name: string;
  type: string;
};

const StyledTh = ({ children }: any) => {
  return (
    <Th textTransform="none" color="grayBlue.80">
      {children}
    </Th>
  );
};

export const UserCardsCard = () => {
  const [selectedCard, setSelectedCard] = useState<string | undefined>();

  const { data: cards, isLoading } = useQuery<Card[]>(
    ["cards", "dashboard"],
    async () => {
      return accountApi.cards();
    },
    {
      retry: false,
    }
  );

  useEffect(() => {
    setSelectedCard(cards?.[0]?.number);
  }, [cards]);

  return (
    <DashboardCard>
      <Box mb={2} px={2}>
        <Text fontWeight={800} fontSize={14} color="grayBlue.70">
          Card List
        </Text>
      </Box>

      <Flex
        py={6}
        gap={6}
        overflowX="scroll"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {cards?.map((card) => (
          <Card
            key={card.id}
            card={card}
            selected={selectedCard === card.number}
            onClick={() => setSelectedCard(card.number)}
          />
        ))}
        <EmptyCard />
      </Flex>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <StyledTh>Amount</StyledTh>
              <StyledTh>Info</StyledTh>
              <StyledTh>Date</StyledTh>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td color="grayBlue.50">$ 2,804.80</Td>
              <Td
                color="grayBlue.70"
                fontSize={12}
                maxW={140}
                overflow="hidden"
                textOverflow="ellipsis"
              >
                APPLE.COM/BILL, Hollyhill Industrial Estate...
              </Td>
              <Td color="grayBlue.70" fontSize={12}>
                01.09.2022
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      <Flex justifyContent="space-between" mt={4}>
        <Box>
          <OutlinedIconButton
            ariaLabel="Previous"
            icon={<ChevronLeftIcon />}
            mr={2}
          />
          <OutlinedIconButton ariaLabel="Next" icon={<ChevronRightIcon />} />
        </Box>
        <OutlinedButton px={6}>All Card Transactions</OutlinedButton>
      </Flex>
    </DashboardCard>
  );
};
