import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Img,
  Input,
  Spacer,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

// const API_URL = "http://localhost:8787/api/design-settings";
const API_URL = "https://kw1.gemba.dev/api/design-settings";

export const SetThemePage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [sourceUrl, setSourceUrl] = useState(
    searchParams.get("companyUrl") || ""
  );
  const [refresh, setRefresh] = useState(false);
  const [theme, setTheme] = useState({
    primaryColor: "#2066E6",
    logo: "https://ge.mba/wp-content/themes/gemba/images/logo.svg",
    favicon: "/logo-gemba-192.png",
    logoBg: "#2066E6",
  });

  const { isFetching, refetch } = useQuery(
    ["theme", sourceUrl],
    async () => {
      if (sourceUrl) {
        const response = await axios.get(
          `${API_URL}?rootUrl=${sourceUrl}&refresh=${refresh ? "1" : ""}`
        );

        const { favicon, logo, colors } = response.data;

        setTheme({
          primaryColor: colors.primary,
          logo,
          favicon,
          logoBg: colors.logoBg || "#ffffff",
        });
      }
    },
    {
      enabled: false,
      cacheTime: 3_600_000,
      staleTime: 3_600_000,
      retry: false,
      refetchOnMount: false,
    }
  );

  const handleApplyTheme = async () => {
    sessionStorage.setItem("token", "");
    sessionStorage.setItem(
      "design-settings",
      JSON.stringify({
        colors: {
          primary: theme.primaryColor,
          logoBg: theme.logoBg === "#ffffff" ? null : theme.logoBg,
        },
        logo: theme.logo,
        favicon: "",
      })
    );
    window.location.href = "/";
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSourceUrl(e.target.value);
  };
  const handleRefreshChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRefresh(e.target.checked);
  };
  const handlePrimaryColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTheme({ ...theme, primaryColor: e.target.value });
  };
  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTheme({ ...theme, logo: e.target.value });
  };
  const handleFaviconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTheme({ ...theme, favicon: e.target.value });
  };
  const handleLogoBgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTheme({ ...theme, logoBg: e.target.value });
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4} minWidth={"400px"}>
            <FormControl id="sourceUrl">
              <FormLabel>URL</FormLabel>
              <Input type="text" value={sourceUrl} onChange={handleUrlChange} />
            </FormControl>
            <FormControl id="refresh">
              <Checkbox
                checked={refresh}
                onChange={handleRefreshChange}
                colorScheme="blue"
              >
                Ignore cache
              </Checkbox>
            </FormControl>
            <Stack spacing={10}>
              <Button
                variant="gemba"
                size="md"
                onClick={() => refetch()}
                disabled={!sourceUrl || isFetching}
              >
                {/* Load theme */}
                {isFetching ? <Spinner /> : "Load theme"}
              </Button>
            </Stack>
          </Stack>
        </Box>

        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4} minWidth={"400px"}>
            <Text fontSize={20}>Theme settings</Text>
            <FormControl id="primaryColor">
              <FormLabel>Primary color</FormLabel>
              <Input
                type="color"
                value={theme.primaryColor}
                onChange={handlePrimaryColorChange}
              />
            </FormControl>

            <Divider my={4} />

            <Flex alignItems="flex-end">
              <FormControl id="favicon">
                <FormLabel>Favicon url</FormLabel>
                <Input
                  type="url"
                  value={theme.favicon}
                  onChange={handleFaviconChange}
                />
              </FormControl>

              <Img
                src={theme.favicon}
                alt="icon"
                h={"32px"}
                w={"32px"}
                ml={4}
                pb={1}
              />
            </Flex>

            <Divider my={4} />

            <FormControl id="primaryColor">
              <FormLabel>Logo url</FormLabel>
              <Input
                type="url"
                value={theme.logo}
                onChange={handleLogoChange}
              />
            </FormControl>

            <FormControl id="logoBg">
              <FormLabel>Logo background</FormLabel>
              <Input
                type="color"
                value={theme.logoBg}
                onChange={handleLogoBgChange}
              />
            </FormControl>

            <Flex justifyContent={"center"}>
              <Flex
                alignItems={"center"}
                p={4}
                backgroundColor={theme.logoBg}
                borderRadius={4}
              >
                <img
                  src={theme.logo}
                  alt="logo"
                  style={{ maxHeight: "80px", minHeight: "32px" }}
                />
              </Flex>
            </Flex>

            <Spacer />

            <Button variant="gemba" size="md" onClick={handleApplyTheme}>
              Apply theme
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};
