import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { MdOutlinePersonOutline, MdOutlineSettings } from "react-icons/md";

export const UserInfo = () => {
  return (
    <Flex alignItems="center" p={8}>
      <Avatar src="https://randomuser.me/api/portraits/men/4.jpg" mr={2} />
      <Box flex={1}>
        <Text fontSize={10} color="#7d8fb2">
          White Label
        </Text>
        <Text fontSize={12} color="#405580">
          The chain bank
        </Text>
      </Box>
      {/* <Text fontSize={16} mr={2}>
        <MdOutlinePersonOutline />
      </Text>
      <Text fontSize={16}>
        <MdOutlineSettings />
      </Text> */}
    </Flex>
  );
};
