import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdOutlineRefresh } from "react-icons/md";
import { Link, generatePath } from "react-router-dom";
import WebFont from "webfontloader";

import { WizardCard } from "../WizardCard";

const fonts = [
  "Open Sans",
  "Roboto",
  "Noto Sans JP",
  "Montserrat",
  "Lato",
  "Poppins",
  "Roboto Condensed",
  "Oswald",
  "Inter",
];

export const FontTab = () => {
  const [selectedFont, setSelectedFont] = useState<string | undefined>();

  useEffect(() => {
    WebFont.load({
      google: {
        families: fonts,
      },
    });
  }, []);

  return (
    <Box mx={12} my={0}>
      <Heading as="h1" size="2xl" fontSize={36} mb={6} fontWeight={500}>
        Pick Fonts of design elements
      </Heading>
      <Flex w="100%">
        <Box width="60%" maxW={800} mr={8}>
          <Grid templateColumns="repeat(3, 1fr)" gap={6} mb={16}>
            {fonts.map((key) => {
              return (
                <GridItem key={key}>
                  <HStack
                    align="center"
                    justifyContent="center"
                    h="200px"
                    w="200px"
                    bgColor="white"
                    p={6}
                    borderRadius={16}
                    boxShadow="0px 60px 120px rgba(38, 51, 77, 0.05)"
                    borderWidth={6}
                    borderColor={
                      key === selectedFont ? "primary" : "transparent"
                    }
                    cursor="pointer"
                    onClick={() => setSelectedFont(key)}
                  >
                    {/* <Text fontSize={14} fontWeight={500} color="primary" mb={4}>
                      {key}
                    </Text> */}
                    <Text
                      align="center"
                      color="primary"
                      fontSize={32}
                      fontFamily={key}
                      fontWeight={400}
                      fontStyle="normal"
                    >
                      {key}
                    </Text>
                  </HStack>
                </GridItem>
              );
            })}
          </Grid>

          <Flex justifyContent="space-between" mb={4}>
            <Button
              variant="outline"
              onClick={() => setSelectedFont(undefined)}
            >
              <Icon as={MdOutlineRefresh} fontSize={20} mr={1} />
              Reset
            </Button>
            <Button
              variant="primary"
              w={300}
              as={Link}
              to={generatePath("/white-label/logo")}
            >
              Save
            </Button>
          </Flex>
        </Box>

        <Box w="40%">
          <WizardCard />

          {selectedFont && (
            <Box
              mt={8}
              py={4}
              px={8}
              bgColor="white"
              boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
              borderRadius={8}
              maxW={500}
            >
              <Text color="primary" fontSize={16} fontWeight={600} mb={4}>
                You selected {selectedFont}
              </Text>

              <Text
                fontSize={20}
                fontFamily={selectedFont}
                fontWeight={400}
                fontStyle="normal"
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </Text>
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
};
