import {
  Button,
  Link as ChakraLink,
  Flex,
  IconButton,
  LinkBox,
  LinkOverlay,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import { accountApi } from "../../api/accountApi";
import { CopyIcon } from "../../assets/icons/Copy";
import { EditIcon } from "../../assets/icons/Edit";
import { UserIcon } from "../../assets/icons/User";
import { formatBalance } from "../../utils/formatters";

export type AccountCardData = {
  currency: string;
  type: string;
  iban: string;
  closingBalance: number;
  openingBalance: number;
};

type Props = {
  accountId: string;
  type: string;
  iban?: string;
};

export const AccountCard = ({ accountId, type }: Props) => {
  const { data, isLoading } = useQuery<AccountCardData>(
    ["account-card", "statement", accountId],
    async () => {
      const statement = await accountApi.getStatement(accountId);

      return {
        currency: statement.balance.currency,
        type,
        iban: statement.balance.iban,
        openingBalance: statement.balance.opening.balance,
        closingBalance: statement.balance.closing.balance,
      };
    },
    {
      retry: false,
    }
  );

  if (isLoading) {
    return null;
  }

  if (!data) {
    return null;

    // return <div>Not found</div>;
  }

  return (
    <LinkBox>
      <Flex
        minW={300}
        direction="column"
        bgColor="white"
        boxShadow="0px 3px 6px rgba(38, 51, 77, 0.05)"
        p={3}
        borderRadius={16}
        _hover={{
          boxShadow: "0px 10px 15px rgba(38, 51, 77, 0.05)",
        }}
        transition="0.1s box-shadow linear"
      >
        <Text fontSize={20} fontWeight={700} color="black" mb={1}>
          {data.currency}
        </Text>

        <Flex mb={2} alignItems="center">
          <UserIcon color="grey.70" />
          <Text
            textTransform="capitalize"
            mx={1.5}
            fontSize={12}
            fontWeight={500}
            color="grey.70"
          >
            {data.type}
          </Text>
          <EditIcon color="grey.70" />
        </Flex>
        <Flex alignItems="center">
          <Text
            textTransform="capitalize"
            mr={1}
            fontSize={14}
            fontWeight={700}
            color="grey.85"
          >
            {data.iban}
          </Text>
          <IconButton aria-label="copy" size="sm" p={1}>
            <CopyIcon color="grey.70" fontSize={14} />
          </IconButton>
        </Flex>
        <Text fontWeight={600} fontSize={18} color="black">
          {formatBalance(data.closingBalance, data.currency)}
        </Text>
        <Text fontWeight={500} fontSize={10} color="grey.85">
          {formatBalance(data.openingBalance, data.currency)}
        </Text>
        <Flex justify="space-between" align="center">
          <LinkOverlay
            as={Link}
            to={`/accounts/${accountId}`}
            color="primary"
            fontSize={13}
            fontWeight={600}
          >
            View transactions
          </LinkOverlay>

          <Button variant="primary" size="sm">
            Pay
          </Button>
        </Flex>
      </Flex>
    </LinkBox>
  );
};
