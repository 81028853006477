import { Box, Flex, IconButton, Text } from "@chakra-ui/react";

import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import { OutlinedIconButton, PrimaryButton } from "../../../components/Button";
import { DashboardCard } from "../../../components/Card";
import agent from "./img/agent.png";

export const BecomeAgentCard = () => {
  return (
    <DashboardCard
      bgImage={`url(${agent})`}
      backgroundRepeat="no-repeat"
      backgroundPosition="225px 6px"
      backgroundSize="contain"
      w={450}
      h={180}
    >
      <Flex direction="column" height="100%">
        <Flex w="100%" justifyContent="space-between">
          <Text color="primary" fontSize={20} fontWeight={600} mr={8}>
            Become an agent
          </Text>

          <OutlinedIconButton
            aria-label="Share"
            icon={<ShareIcon />}
          ></OutlinedIconButton>
        </Flex>

        <Text color="grayBlue.70" fontSize={12} maxW={200}>
          Open an agent account for your partners without borders
        </Text>

        <Box flex={1} />

        <PrimaryButton w="100%">Open Gemba White Label</PrimaryButton>
      </Flex>
    </DashboardCard>
  );
};
