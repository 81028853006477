import { Icon } from "@chakra-ui/react";

export const ExpandIcon = (props: any) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M7.91667 3.75V5.41667H13.4083L3.75 15.075L4.925 16.25L14.5833 6.59167V12.0833H16.25V3.75H7.91667Z"
      fill="currentColor"
    />
  </Icon>
);
