import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
} from "@chakra-ui/react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { MdOutlineDns, MdOutlineRefresh } from "react-icons/md";

import { WizardCard } from "../WizardCard";
import { ColorPicker } from "./ColorPicker";

const settings = [
  {
    label: "Links color",
    color: "#2066E6",
  },
  {
    label: "Form input text",
    color: "#E7E7E7",
  },
  {
    label: "Pager link border",
    color: "#2066E6",
  },
  {
    label: "Links color",
    color: "#2066E6",
  },
  {
    label: "Links color",
    color: "#2066E6",
  },
];

export const ColorTab = () => {
  return (
    <Box mx={12} my={0}>
      <Heading as="h1" size="2xl" fontSize={36} mb={6} fontWeight={500}>
        Pick colors of design elements
      </Heading>

      <Flex>
        <Box width="60%" maxW={800} mr={8}>
          <Grid templateColumns="repeat(3, 1fr)" gap={6} mb={6}>
            {[
              ...settings,
              ...settings,
              ...settings,
              ...settings,
              ...settings,
            ].map((s, i) => {
              return (
                <GridItem key={`${s.label}-${i}`}>
                  <Flex alignItems="center">
                    <ColorPicker initialColor={s.color} />
                    <Text
                      color="grayBlue.70"
                      fontWeight={600}
                      fontSize={13}
                      ml={2}
                    >
                      {s.label}
                    </Text>
                  </Flex>
                </GridItem>
              );
            })}
          </Grid>

          <InputGroup
            mb={8}
            transition="box-shadow .3s"
            _hover={{
              boxShadow: "0px 2px 15px rgba(51, 191, 255, 0.15)",
            }}
          >
            <InputLeftAddon
              pointerEvents="none"
              bgColor="#fff"
              color="grayBlue.50"
              fontSize={20}
              children={
                <>
                  <Box as={MdOutlineDns} mr={2} />
                  <Text fontSize={14} fontWeight={700}>
                    Preset name
                  </Text>
                </>
              }
            />
            <Select borderLeftRadius={0} bgColor="#fff">
              <option value="option1">Gemba Preset</option>
              <option value="option2">Custom</option>
              <option value="option3">Option 3</option>
            </Select>
          </InputGroup>

          <Flex justifyContent="space-between" mb={4}>
            <Button variant="outline">
              <Icon as={AiOutlineCloudDownload} fontSize={20} mr={1} />
              Save as template
            </Button>
            <Button variant="outline">
              <Icon as={MdOutlineRefresh} fontSize={20} mr={1} />
              Reset
            </Button>
            <Button variant="primary" w={300}>
              Save
            </Button>
          </Flex>
        </Box>

        <Box w="40%">
          <WizardCard />
        </Box>
      </Flex>
    </Box>
  );
};
