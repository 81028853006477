import { SmallCloseIcon } from "@chakra-ui/icons";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { NavLink } from "react-router-dom";

import { api } from "../../api/api";
import { ExpandIcon } from "../../assets/icons/ExpandIcon";
import { OutlinedIconButton } from "../../components/Button";
import { Message } from "./types";

export const ViewMessage = ({ id, onClose }: any) => {
  const { data: message, isLoading } = useQuery<Message>(
    ["messages", id],
    async () => {
      const { data } = await api.get(`/api/messages/${id}`);
      return data;
    }
  );

  console.log({ message });
  if (!message) {
    return null;
  }

  return (
    <Box
      bgColor="#fff"
      borderRadius={16}
      boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
      position="relative"
    >
      <Box>
        <Box p={6}>
          <Flex mb={4} justifyContent="flex-end">
            {onClose && (
              <OutlinedIconButton
                as={NavLink}
                to={`/messages/${id}`}
                border="none"
                ariaLabel="Expand"
                icon={<ExpandIcon />}
                mr={4}
              />
            )}
            {onClose && (
              <OutlinedIconButton
                onClick={onClose}
                ariaLabel="Close"
                icon={<SmallCloseIcon />}
              />
            )}
            {!onClose && (
              <OutlinedIconButton
                as={NavLink}
                to="/messages"
                ariaLabel="Close"
                icon={<SmallCloseIcon />}
              />
            )}
          </Flex>

          <Flex>
            <Text mr={2} fontWeight={700} color="grayBlue.80">
              From:
            </Text>
            <Text fontWeight={700} color="grayBlue.50">
              {message.author}
            </Text>
          </Flex>

          <Divider my={4} />

          <Box>
            <Text fontWeight={700} color="grayBlue.50">
              {message.subject}
            </Text>
          </Box>

          <Divider my={4} />

          <Box mb={4}>
            <Text color="grayBlue.50" fontWeight={700}>
              {message.text}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
