import { Box, Flex, Text } from "@chakra-ui/react";
import { CloudUploadIcon } from "assets/icons/CloudUpload";
import { DropzoneOptions, useDropzone } from "react-dropzone";

export const ImgUpload = (options: DropzoneOptions) => {
  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
  //   return () =>
  //     files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  // }, [files]);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone(options);

  return (
    <Flex
      alignItems="center"
      borderRadius={16}
      border={isDragActive ? "2px dashed #2066E6" : "2px dashed #CFD6E6"}
      backgroundColor={isDragActive ? "#f2f5ff" : "#fff"}
      mb={4}
      p={2}
      cursor="pointer"
      {...getRootProps()}
    >
      <input name="files" {...getInputProps()} />
      <Box p={4}>
        <CloudUploadIcon w="24px" h="24px" color="grayBlue.85" />
      </Box>
      <Flex direction="column" p={4}>
        <Text fontSize={14} fontWeight={700} color="#000">
          {isDragActive ? "Drop the files here" : "Choose files"}
        </Text>
        <Text fontSize={14} fontWeight={500} color="#B8C3D9">
          Drag & Drop or click to upload
        </Text>

        <Text fontSize={10} fontWeight={500} color="#B8C3D9">
          (PDF/JPG/PNG) less than 10 MB
        </Text>
      </Flex>
    </Flex>
  );
};
