import axios from "axios";

import config from "../config";

export type Statement = {
  issue_date: string;
  balance: {
    iban: string;
    currency: string;
    opening: {
      date: string;
      balance: number;
    };
    closing: {
      date: string;
      balance: number;
    };
  };
  records: StatementRecord[];
};

export type StatementRecord = {
  document_reference: string;
  external_id: string;
  transaction_reference: string;
  date: string;
  type: string;
  amount: number;
  currency: string;
  details: string;
  recipient_bic: string;
  recipient_account: string;
  recipient_name: string;
  sender_bic: string;
  sender_account: string;
  sender_name: string;
  accountId: string;
};

class AccountApi {
  private http;

  constructor(baseUrl: string) {
    this.http = axios.create({
      baseURL: baseUrl,
    });
  }

  async list() {
    const { data } = await this.http.get("/api/accounts");
    return data;
  }

  async cards() {
    const { data } = await this.http.get("/api/cards");
    return data;
  }

  async getStatement(accountId: string): Promise<Statement> {
    const { data } = await this.http.get(
      `/api/accounts/${accountId}/statement`
    );

    return data;
  }
  async getTransactions(
    accountId: string,
    page = 0,
    limit = 5
  ): Promise<Statement> {
    const { data } = await this.http.get(
      `/api/transactions?accountId=${accountId}`,
      { params: { page, limit } }
    );

    return data;
  }
}

export const accountApi = new AccountApi(config.apiBaseUrl);
