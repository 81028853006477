import { Badge as BaseBadge } from "@chakra-ui/react";
import { defineStyleConfig } from "@chakra-ui/react";

type Props = {
  text: string;
  bgColor?: string;
  color?: string;
};

export const Badge = ({ text, bgColor = "primary", color = "#fff" }: Props) => {
  return (
    <BaseBadge
      bgColor={bgColor}
      color={color}
      borderRadius={16}
      fontSize={12}
      fontWeight={800}
      px={4}
      py={1}
      mr={4}
    >
      {text}
    </BaseBadge>
  );
};

export const BadgeTheme = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    borderRadius: 16,
    fontWeight: 800,
    textTransform: "none",
    // borderRadius: "base", // <-- border radius is same for all variants and sizes
  },
  // Two sizes: sm and md
  sizes: {
    md: {
      px: 4, // <-- these values are tokens from the design system
      py: 1, // <-- these values are tokens from the design system
      fontSize: 10,
      // h: 8,
      borderRadius: 22,
    },
  },
  // Two variants: outline and solid
  variants: {
    primary: {
      color: "white",
      bgColor: "primary",
    },
    success: {
      backgroundColor: "primary",
      color: "white",
      _hover: {
        bgColor: "white",
        color: "primary",
        borderColor: "primary",
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "primary",
  },
});
