import { Box } from "@chakra-ui/react";

import { NewMessageForm } from "./NewMessageForm";

export const NewMessagePage = () => {
  return (
    <Box p={8}>
      <NewMessageForm />
    </Box>
  );
};
