import { Box, Flex, Heading } from "@chakra-ui/react";

import { PaymentDetails } from "../common/PaymentDetails";
import { InternationalPaymentsForm } from "./InternationalPaymentsForm";

export const InternationalPaymentsTab = () => {
  return (
    <Box mx={16} my={8}>
      <Heading
        as="h1"
        size="2xl"
        noOfLines={1}
        fontSize={36}
        mb={4}
        fontWeight={500}
      >
        International payments
      </Heading>

      <InternationalPaymentsForm />
    </Box>
  );
};
