import { Textarea } from "@chakra-ui/react";
import { Formik } from "formik";

import { FileUpload } from "components/Form";

export const TransactionNotes = () => {
  return (
    <Formik
      initialValues={{ notes: [] }}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          actions.setSubmitting(false);
        }, 1000);
      }}
    >
      <>
        <Textarea
          rows={6}
          border="2px solid #F0F2F7"
          boxShadow="0px 2px 5px rgba(38, 51, 77, 0.03)"
          borderRadius={12}
          fontWeight={800}
          placeholder="Add a note..."
          mb={4}
          bgColor="#fff"
          resize="none"
        ></Textarea>

        <FileUpload
          name="notes"
          buttonLabel=""
          fileTypeLabel="(PDF/JPG/PNG)"
          options={{ multiple: true }}
        />
      </>
    </Formik>
  );
};
