import { Button } from "@chakra-ui/react";

export const PrimaryButton = ({ children, ...rest }: any) => {
  return (
    <Button
      backgroundColor="primary"
      color="white"
      border="2px"
      borderColor="transparent"
      borderRadius={16}
      fontSize={12}
      h={8}
      py={4}
      _hover={{
        bgColor: "white",
        color: "primary",
        borderColor: "primary",
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};
