import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { ViewMessage } from "./ViewMessage";

export const ViewMessagePage = () => {
  const { messageId } = useParams();

  return (
    <Box p={8}>
      <ViewMessage id={messageId} />
    </Box>
  );
};
